import  { useEffect, useState } from 'react'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { commonToast, hitApi, skeletonLoader, AddDataimg, teamMemberRestrictionMess } from '../../../../components/commonAction';
import ConfirmationPopup from '../../../../components/common/ConfirmationPopup';
import svg from '../svg';
import { useStore } from '../../../../zustand/store';

function TicketPrioritySettings() {
  const [priorityList, setPriorityList] = useState([]);

  let store = useStore(state => state);
  const userData = store.userData;
  let { role } = userData;
  let allowAccess = (role === 'client') ? false : true;


  const [addPriorityBox, setAddPriorityBox] = useState(false);
  const [DeleteId, setDeleteId] = useState();
  const [name, setName] = useState('');
  const [editPriorityId, setEditPriorityId] = useState('');
  const [showPopUp, setShowPopup] = useState(false);
  const [shownoData, setShowNoData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const[btnLoader,setBtnLoader]= useState(false);

  const addPriority = (e) => {
    e.preventDefault();

    if (!name) {
      commonToast('error', 'Field can not be empty!');
      return false;
    } else {
      const data = {
        name: name,
        isdefault: 0
      }
      setBtnLoader(true)
      hitApi({
        url: editPriorityId ? ('pixa-support/api/manageTicket_priority?id=' + editPriorityId) : 'pixa-support/api/manageTicket_priority',
        method: "POST",
        data: data,
        loading: false,
        alert: false,

      }, (resp, err = null) => {
        setBtnLoader(false);
        if (resp.status === 1) {
          fetchAllpriority();
          setName('');
          setAddPriorityBox(false);
          setEditPriorityId('');
        }
      });

    }
  }

  useEffect(() => {
    fetchAllpriority();
  }, [])

  const fetchAllpriority = () => {
    if (userData.userId) {
      setLoading(true)
      hitApi({
        url: 'pixa-support/api/getAllTicketPriority',
        method: "POST",
        data: {
          userId: userData.role === 'user' ? userData.userId : userData.userId,
        },
        loading: false,
        alert: false,

      }, (resp, err = null) => {
        setLoading(false)
        if (resp.status === 1) {

          setPriorityList(resp.data);
          if (resp.data.length === 0) {
            setShowNoData(true);
          } else {
            setShowNoData(false);
          }
          setIsError(false)
        } else {
          setIsError(true)
        }
      });



    }
  }

  const handleDelete = (id) => {
    setShowPopup(true);
    setDeleteId(id);
  }
  useEffect(()=>{
    let element = document.getElementById("#element");
    if (element) {
      element.scrollTop = element.scrollHeight + 100;
    }
})


  const editPriority = (id) => {
    setEditPriorityId(id);
    setAddPriorityBox(true);
    setName(priorityList.find(item => item._id === id).name);
  }


  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };
  const pageDragHandle = (result) => {
    if (!result.destination) {
      return;
    }
    const items = reorder(
      priorityList,
      result.source.index,
      result.destination.index
    );
    setPriorityList(items);
    hitApi({
      url: 'pixa-support/api/tickets_ChangeOrder',
      method: "POST",
      data: { list: items, status: "priority" },
      loading: false,
      alert: true,

    }, (resp, err = null) => {
      if (resp.status == 1) {
        fetchAllpriority();
      }
    });

  }

  return (
    <>
      <div className='proj_domai_set_form'>
        <div id='#element' className={`sup_proj_setting_form pxl-ticketStatusBox  ${allowAccess ? "" : "support_type_prio"}`}>
          <h4 className='proj_main_head'>Ticket Priority</h4>
          <div className='pxl_settingboxMain'>
              <div className='pxl_settingboxInner'>
              <DragDropContext onDragEnd={pageDragHandle}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {!loading ? (priorityList.length > 0) ? priorityList.map((priority, index) =>
                        <Draggable key={priority._id} draggableId={(priority._id).toString()} index={index}>
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              className={`pxl-ticket-status-wrap pxl-ticket-status-color${index + 1 > 5 ? index % 5 + 1 : index + 1} pxl-flex-divide`}
                              title={priority.name}
                            >
                              <div className='pxl-ticket-status-name'>
                                <h2 className='pxl-font-16-weight-400'> {priority.name}</h2>
                              </div>
                              <div className='pxl_ticket_Set_type_prior'>

                                <ul className='pxl-flex-divide'>
                                  {priority.isdefault ? null :
                                  <>
                                   <li className={`cun-socialIcon pxl-tooltip-button-form pxl-deleteToltip ${allowAccess ? "" : 'pxl-tooltip-btn-diable long-tooltip-data'}`} >
                                    <button disabled={!allowAccess} onClick={() => editPriority(priority._id)}>
                                      {svg.dt_edit_icon}
                                      <div className="cun-show-tooltip"><p>{allowAccess ? "Edit" : teamMemberRestrictionMess(allowAccess)}</p></div>
                                    </button>
                                   
                                  </li>
                                  <li className={`cun-socialIcon pxl-tooltip-button-form pxl-deleteToltip ${allowAccess ? "" : 'pxl-tooltip-btn-diable long-tooltip-data'}`} >
                                    <button disabled={!allowAccess} className='pxl-pink-color' onClick={() => {handleDelete(priority._id); setAddPriorityBox(false)}}>
                                      {svg.deleteWhiteSvg}
                                      <div className="cun-show-tooltip"><p>{allowAccess ? "Delete" : teamMemberRestrictionMess(allowAccess)}</p></div>
                                    </button>
                                    
                                  </li>
                                  </>
                                  }
                                  <li className='cun-socialIcon pxl-tooltip-button-form pxl-deleteToltip DragTooltip'>
                                  {allowAccess ?  <a {...provided.dragHandleProps}>
                                      {svg.dragDropSvg}
                                    </a> :
                                    <a href="#" onClick={(e)=>{
                                      e.preventDefault()
                                     }}>
                                      {svg.dragDropSvg}
                                    </a>
                                    }
                                 {allowAccess && <div className="cun-show-tooltip pxldrag-disable"><p>Drag</p></div>}
                                  </li>
                                </ul>

                              </div>
                            </div>
                          )}
                        </Draggable>
                      ) :!addPriorityBox? <AddDataimg title={"No ticket priority found."} bgRemove={true} changeDesign={true} />:null : null
                      }

                      {loading ? <div className='mb-3'>
                        {skeletonLoader('list', 3)}
                      </div>
                        : null
                      }
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
            {addPriorityBox ? <form onSubmit={addPriority}>
              <div className='pxl-add-status-heading'>
                <h3 className='pxl-font-18-weight-600'>{editPriorityId ? 'Edit' : 'Add'} Priority</h3>
                <div className="pxl-ticket-status-popup">
                  <div className="pxl_input_feilds">
                    <label>Priority Title <span className="pxl-star-red">*</span></label>
                    <input type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder='Enter priority title' />
                  </div>

                </div>
                <div className='pxl-setting-button-wrap pxl-addCancelBtn'>
                  <button type='submit' className="pxlCommon-btn " disabled={btnLoader}>{btnLoader ? "Processing..." :  editPriorityId ? 'Update' : 'Add'}</button>
                  <button type="button" className="pxlCommon-btn Pxl-cancel-btn" disabled={btnLoader} onClick={() => {setAddPriorityBox(false);setName('');setEditPriorityId('')}}>Cancel</button>
                </div>
                
              </div></form>
              :
              <div className='pxl-ticket-status-tooltip cun-socialIcon'>
                <button disabled={!allowAccess} className={`pxlCommon-btn ${!allowAccess ? "sch-btn-disbale" : ""}`} onClick={() => setAddPriorityBox(true)}> Add New Priority</button>
                {!allowAccess && <div className="cun-show-tooltip cundisabletooltip"><p>{teamMemberRestrictionMess()}</p></div>}

              </div>
            }
          </div>
        </div>
      </div>
      <ConfirmationPopup
        shownPopup={showPopUp}
        closePopup={() => {
          setDeleteId('');
          setShowPopup(false);
        }}
        type={"ticket priority"}
        removeAction={() => {

          hitApi({
            url: `pixa-support/api/deleteTicketPriority?id=${DeleteId}`,
            method: "POST",
            data: {},
          }, (resp, err = null) => {
            setShowPopup(false);
            setDeleteId('');
            if (resp.status) {
              fetchAllpriority();
            }
          })
        }}
      />
    </>
  )
}

export default TicketPrioritySettings