import { useStore } from "../zustand/store";
import NoPage from "./404";

const PrivateRoute = ({ children, allowedRoles, apiendpoint}) => {
  let { role } = useStore(state => state.userData);


  // if (allowedRoles?.map(i=>i==role)) {
  
  if(process.env.REACT_APP_APP_MODE == 'live' && role == 'user' && apiendpoint)
  {
    return (<NoPage />)
  }
  else if (allowedRoles.includes(role)) {
    return (
      <>
        {children}
      </>
    );
  } else {
    // window.location.href='/dashboard' ;
    return (<NoPage />)
  }
};

export default PrivateRoute;

export const TeamMemberScope = ({ children, allowedscope, excludeheader = false }) => {
  let { scope, role } = useStore(state => state.userData);
  if (role != "teamMember") {
    return (
      <>
        {children}
      </>
    );
  }
  else if (scope.hasOwnProperty(allowedscope)) {
    return (
      <>
        {children}
      </>
    );
  } else if (excludeheader) {
    return <></>
  } else {
    return (<NoPage />)
  }
};
