import { useState, useEffect, useRef } from 'react'
import svg from './svg';
import { commonToast, hitApi, validations } from '../../../components/commonAction';
import { Modal } from 'react-bootstrap';
import { Box, Tooltip } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
function MailSettings({ p_id, setActiveTabMenu }) {

    const [supportName, setSupportName] = useState('')
    const [supportMail, setSupportMail] = useState('');
    const [copyMessShow, setCopyMessShow] = useState(false);
    const [Verify, setVerify] = useState(false);
    const [verifiedData, setVerifiedData] = useState('');
    const [VerifyPopup, setVerifyPopup] = useState(false);
    const [isEdit, setIsEdit] = useState(false)
    const [status, setStatus] = useState(0)
    const [projectID, setProjectId] = useState('')
    const [smtpHost, setSMTPHost] = useState('')
    const [smtpPort, setSMTPPort] = useState('')
    const [smtpUsername, setSMTPUsername] = useState('')
    const [smtpPassword, setSMTPPassword] = useState('')
    const [SSL, setSSL] = useState(false)

    const [selectedOption, setSelectedOption] = useState('imap');
    const [btnLoader, setBtnLoader] = useState(false);
    const project_id = p_id;


    useEffect(() => {
        if (project_id) {
            setProjectId(project_id)
            hitApi({
                url: 'pixa-support/api/project_details',
                method: "POST",
                data: {
                    project_id: project_id,
                },
                loading: true,
                alert: false,
            }, (resp, err = null) => {
                if (resp.status) {
                    if (resp.data.mail) {
                        setSelectedOption((resp.data?.mail_type != undefined) ? resp.data?.mail_type : 'forwarder')
                        setSupportName(resp?.data?.mailName)
                        setSupportMail(resp?.data?.mail);
                        setVerify(resp?.data?.mailVerified);
                        setSMTPHost(resp?.data?.smtpHost)
                        setSMTPPort(resp?.data?.smtpPort)
                        setSMTPUsername(resp?.data?.smtpUsername)
                        setSMTPPassword(resp?.data?.smtpPassword)
                        setSSL(resp?.data?.ssl)
                        setStatus(1);
                    }
                }
            });

        }
    }, [project_id])

    const handleSave = () => {
        
        if (supportName.trim() === '' || smtpHost.trim() === '' || smtpPort == '' || smtpUsername.trim() === '' || smtpPassword.trim() === '') {
            commonToast('error', `All Fields are required.`);
            return false;
        }
        if (!supportMail.trim()) {
            commonToast('error', `Support Email is required!`);
            return false;
        }
        if (validations(supportMail, 'email')) {
            setBtnLoader(true);

            hitApi({
                url: 'pixa-support/api/createUpdateMail',
                method: "POST",
                data: { project_id: project_id, type: selectedOption.toLocaleLowerCase(), mail: supportMail, name: supportName, smtpHost: smtpHost, smtpPort: smtpPort, smtpUsername: smtpUsername, smtpPassword: smtpPassword, ssl: SSL },
                loading: true,
                alert: true,
            }, (resp, err = null) => {
                setBtnLoader(false);
                if (resp.status === 1) {
                    setStatus(1)
                    setVerify(resp?.data?.mailVerified)
                    setTimeout(() => {
                        setActiveTabMenu('widget')
                    })
                }
            });
        }


    }

    const verifyEmail = () => {
        hitApi({
            url: 'pixa-support/api/verifyMailForwarder',
            method: "POST",
            data: { email: supportMail },
            loading: true,
            alert: false,
        }, (resp, err = null) => {
            if (resp.status === 1) {
                setStatus(3)

            }
        });

    }

    const sendConfirmationMail = () => {
        setStatus(4)
        setTimeout(() => {

            hitApi({
                url: 'pixa-support/api/sendconfirmationmail',
                method: "POST",
                data: { email: supportMail, project_id: projectID },
                loading: true,
                alert: false,
            }, (resp, err = null) => {
                if (resp.status === 1) {
                    setStatus(6)
                }
                else {
                    setStatus(5)
                }
            });

        }, 2000);
    }


    const verifySuccess = () => {
        setVerifyPopup(false);
        hitApi({
            url: 'pixa-support/api/updateMailStatus',
            method: "POST",
            data: { project_id: projectID },
            loading: true,
            alert: false,
        }, (resp, err = null) => {
            if (resp.status === 1) {
                setVerify(resp.data.mailVerified)
                setStatus(1)
            }
        });

    }
    useEffect(() => {
        if (isEdit) setStatus(0)
    }, [isEdit])

    // if (isEdit === true) {
    //     setStatus(0)
    //     setIsEdit(false)
    // }


    const closeVerifyPopUp = () => {
        setStatus(1)
        setVerifyPopup(false);
        setVerifiedData('')
    }
    const handleLinks = (url) => {
        var openWindow = window.open(url, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
    }
    const firstInputRef = useRef(null);

    useEffect(() => {
        if (firstInputRef.current) {
            firstInputRef.current.focus();
        }
    }, []);

    return (

        <>
            <div className='proj_mail_set_form'>
                <div className='sup_proj_setting_form pxl-mail-setting-wrap'>
                    <h4 className='proj_main_head'>Mail Settings</h4>
                    {(status == 0) && <>
                        <div className='proj_Setting_first_flex pxl-project-mailSettingTop-field' style={{ alignItems: 'flex-end' }}>
                            <div className="pxl_input_feilds pxl-placeholder-color1 marginBottom">
                                <label> Name <span className="pxl-star-red">*</span></label>
                                <input ref={firstInputRef} type="text" name="" placeholder="Enter Name" value={supportName} onChange={(e) => setSupportName(e.target.value)} />
                            </div>
                            <div className="pxl_input_feilds pxl-placeholder-color1">
                                <label>Your Support Email <span className="pxl-star-red">*</span></label>
                                <input type="text" name="" placeholder="Enter Support Email" value={supportMail} onChange={(e) => setSupportMail(e.target.value)} />
                            </div>
                            <div className="cun-mailSetting-radiobox-wrapper-box">
                                {['imap', 'forwarder'].map((option, i) => (
                                    <div key={i} className="cun-custom-radioBtn">
                                        <input type="radio" id={option} value={selectedOption} checked={selectedOption === option} name={'media_type'} onChange={(e) => setSelectedOption(option)} />
                                        <label htmlFor={option} style={{ textTransform: 'capitalize' }}>{option}</label>
                                    </div>
                                ))
                                }
                            </div>
                        </div>
                        <div className='pxl-mailSetting-mainWrapper-box pxl-outgointMail-settingBox'>
                            {selectedOption == 'forwarder' && <div className='pxl-malserver-forwarder'>
                                <h4 className='proj_main_head'>Incoming Mail Settings</h4>
                                <h5>PixaSupport Mail Server</h5>
                                <p>Pixasupport mail server manage all email transactions</p>
                                <div className='mail_back_color_box pxl-setupForwarding-box'>
                                    <h5>Setup forwarding email for</h5>
                                    <p><b> Step 1 :</b> Copy forwarding email address below.</p>
                                    <div className='mail_forward_copy'>
                                        <p>forwarder@pixalab.ai</p>

                                        <Tooltip title={copyMessShow && "Copied"} placement="top" arrow>
                                            <div onClick={() => navigator.clipboard.writeText('support@pixalab.ai').then(() => {
                                                setCopyMessShow(true);
                                                setTimeout(() => setCopyMessShow(false), 3000);
                                            })} className={` mail_copy`}>{svg.copy_mail}</div>
                                        </Tooltip>

                                    </div>
                                </div>
                            </div>
                            }
                            <div className='pxl-outgoingMail-setting-box'>
                                <h4 className='proj_main_head'>Outgoing Mail Settings</h4>

                                <div className='mail_back_color_box'>
                                    <h6>Hosted Email Server <span className="pxl-star-red">*</span></h6>
                                    <p>SMTP server name to send emails.</p>
                                    <div className='pxl-smtp-host-wrapper-box'>
                                        <div className='pxl-smtp-hostBox1'>
                                            <div className='pxl-mailsetting-wrap'>
                                                <div className="pxl_input_feilds pxl-placeholder-color1 pxlhost-input bottomSpace">
                                                    <h6 className='mb-2'>SMTP Host <span className="pxl-star-red">*</span></h6>
                                                    <input type="text" name="" placeholder="Enter Your SMTP Host" value={smtpHost} onChange={(e) => setSMTPHost(e.target.value)} />
                                                </div>

                                                <div className="pxl_input_feilds pxl-placeholder-color1 pxlhost-input">
                                                    <h6 className='mb-2'>SMTP Port <span className="pxl-star-red">*</span></h6>
                                                    <input type="text" name="" placeholder="Enter Your SMTP Port" value={smtpPort} onChange={(e) => setSMTPPort(e.target.value)} />
                                                </div>
                                            </div>
                                            <div className='support_checkbox pxl-use-ssl-checkbox'>
                                                <div className="cun-AllChecknoxSelect">
                                                    <div className="sch-custom-check-box ">
                                                        <div className="form-group">
                                                            <input type="checkbox" id="html"
                                                                checked={SSL} onChange={(e) => setSSL(!SSL)}
                                                            />
                                                            <label htmlFor="html"></label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <h6>Use SSL</h6>
                                            </div>
                                        </div>
                                        <div className='pxl-smtp-hostBox1'>
                                        <div className='pxl-mailsetting-wrap01'>
                                        <div className="pxl_input_feilds pxl-placeholder-color1 bottomSpace">
                                            <h6 className='mb-2'> Username <span className="pxl-star-red">*</span></h6>
                                            <input type="text" name="" placeholder="Enter Your Username" value={smtpUsername} onChange={(e) => setSMTPUsername(e.target.value)} />
                                        </div>
                                        <div className="pxl_input_feilds pxl-placeholder-color1">
                                            <h6 className='mb-2'>Password <span className="pxl-star-red">*</span></h6>
                                            <input type="password" name="" placeholder="Enter Your password" value={smtpPassword} onChange={(e) => setSMTPPassword(e.target.value)} />
                                        </div>
                                        </div>
                                        </div>
                                    </div>
                                    <div className='pxl-use-ssl'>
                                        <p>Enables enhanced security.</p>
                                    </div>
                                </div>
                            </div>
                            {/* <div className='pxl-username-email-accountDetails'>
                                <div className="pxl_input_feilds pxl-placeholder-color1 bottomSpace">
                                    <label> Username of your email account <span className="pxl-star-red">*</span></label>
                                    <input type="text" name="" placeholder="Enter Your SMTP Username" value={smtpUsername} onChange={(e) => setSMTPUsername(e.target.value)} />
                                </div>
                                <div className="pxl_input_feilds pxl-placeholder-color1">
                                    <label>Password of your email account <span className="pxl-star-red">*</span></label>
                                    <input type="password" name="" placeholder="Enter Your SMTP password" value={smtpPassword} onChange={(e) => setSMTPPassword(e.target.value)} />
                                </div>
                            </div> */}

                        </div>

                        <div className='pxl-mail-setting-saveBtn'>
                            <button className="pxlCommon-btn" onClick={() => { setActiveTabMenu('project') }}> Back</button>
                            <button className="pxlCommon-btn" type='button' onClick={() => handleSave()}> {isEdit ? 'Update & Continue' : 'Save & Continue'}</button>

                        </div>
                    </>}

                    {status == 1 &&
                        <div className="support_mail_verified_box">
                            <div className='pxl_support_mail_verified_box_text'><h4><span>{supportName}</span> {supportMail}</h4></div>

                            <div className="suppprt_mail_verified_box_btns">
                                {Verify == false ?
                                    <button type="button" className="sch-btn" onClick={(e) => { setVerifyPopup(true); setStatus(2) }}>Verify</button>
                                    :
                                    // <button type="button" className="sch-btn_verify"> {svg.menu_icon_check} Verified</button>
                                    <button class="pxlCommon-btn">Verified</button>
                                }
                                <Tooltip title="Edit" placement="top" arrow>
                                    <div className="actions_btn actions_btn_edit pxl-verify-edit" onClick={() => setIsEdit(true)}>{svg.dt_edit_icon}</div>
                                </Tooltip>

                                {/* <Tooltip title="Delete" placement="top" arrow> */}
                                {/* <div className="actions_btn actions_btn_del" >{svg.icon_delete}</div> */}
                                {/* </Tooltip> */}
                            </div>
                        </div>
                    }
                </div>

            </div>

            <Modal
                show={VerifyPopup} onHide={closeVerifyPopUp}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="pxl_modalDv"
            >


                <Modal.Header closeButton style={{ borderBottom: 'none', padding: '0' }}>

                </Modal.Header>
                <Modal.Body>
                    <div>


                        {status == 2 && <div className="xs_popup_mail_section">
                            <div className="xs_popup_mail_section_heading">
                                <h5>Setup Forwarding</h5>
                                <h6>Setup forwarding email for - {supportMail}</h6>
                            </div>

                            <div className="pxl_mail_setting_mail_box ">

                                <h5>To setup email forwarding, please follow the steps given below and then click next :</h5>
                                <div className="xs_input_wrapper xs_label_span_text mb-0">
                                    <span className="mb-1"><b>Step 1 :</b> Copy forwarding email address below.</span>

                                    <div className="mail_popup">
                                        <div className='mail_forward_copy'>
                                            <p>support@pixalab.ai</p>

                                            <Tooltip title={copyMessShow && "Copied"} placement="top" arrow>
                                                <div onClick={() => navigator.clipboard.writeText('support@pixalab.ai').then(() => {
                                                    setCopyMessShow(true);
                                                    setTimeout(() => setCopyMessShow(false), 3000);
                                                })} className={` mail_copy`}>{svg.copy_mail}</div>
                                            </Tooltip>

                                        </div>
                                    </div>
                                    <span className="xs_mail_setting_mail_step2"><b>Step 2 :</b> Login to your mailbox and go to Settings {">"}  Forwarding. And add the copied email address as a forwarding address.</span>
                                </div>
                            </div>

                            <div className="xs_popup_mail_btn"><button type="button" className="sch-btn" onClick={() => sendConfirmationMail()}>Next</button></div>

                        </div>}



                        {status == 4 && <div className="xs_popup_mail_section">
                            <div className="xs_popup_mail_section_heading">
                                <h5>Activate Forwarding</h5>
                                <span className="">Activate forwarding email for - {supportMail}</span>
                            </div>
                            <div className="pxl_mail_setting_mail_box">
                                <h5>Please wait we are sending an activation email with a code :</h5>
                                <div className="xs_input_wrapper xs_label_span_text mb-0">
                                    <span className="mb-1">We are waiting for the activation code to the PixaSupport forwarding email address. </span>
                                </div>
                                <div className="xs_mail_setting_mail_activation_box">
                                    <div className="pxl_email_swipper_align">
                                        <div>
                                            <Box sx={{ display: 'flex' }}>
                                                <CircularProgress size={'20px'} color={'success'} />
                                            </Box>
                                        </div>
                                        <span>Receiving activation code</span></div>
                                </div>
                            </div>
                        </div>


                        }

                        {status == 5 && <div className="xs_popup_mail_section">
                            <div className="xs_popup_mail_section_heading">
                                <h5>Setup Forwarding</h5>
                                <span className="">Confirm forwarding email for - {supportMail}</span>
                            </div>
                            <div className="pxl_mail_setting_mail_box error_box">
                                <h5>Oppps! We didn't receive your confirmation yet, Please try again.</h5>
                                <div className="xs_input_wrapper xs_label_span_text mb-0">
                                    <span className="mb-1">You will receive forwarding confirmation ticket with a link <a href={process.env.APP_URL + `project/${projectID}`} target="_blank">View Ticket</a>. </span> <br></br>
                                    <div className="xs_mail_setting_mail_step2_mt "><span >Click on the link to follow the steps and confirm the request.</span></div>
                                </div>
                            </div>
                            <div className="xs_popup_mail_btn"><button type="button" className="sch-btn" onClick={() => sendConfirmationMail()}>Retry</button></div>

                        </div>}

                        {status == 6 && <div className="xs_popup_mail_section">
                            <div className="xs_popup_mail_section_heading">
                                <h5>Congratulations</h5>
                                <span className="">Complete setup for - {supportMail}</span>
                            </div>
                            <div className="pxl_mail_setting_mail_box xs_mail_setting_mail_success_box">
                                <h5>Awesome!, Your setup is complete.</h5>
                                <div className="xs_input_wrapper xs_label_span_text mb-0">
                                    <span className="mb-1">Now, with PixaSupport, all emails sent to {supportMail} are automatically converted into tickets in the dashboard. Stay organized and streamline your support process with PixaSupport. </span>
                                </div>
                            </div>
                            <div className="xs_popup_mail_btn"><button type="button" className="sch-btn" onClick={() => verifySuccess()}>Finish</button></div>

                        </div>}
                    </div>
                </Modal.Body>
            </Modal>
        </>

    )
}

export default MailSettings