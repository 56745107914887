
// const Text_setting = dynamic(
//   () => import("@/components/imageEditoretting"),
//   {
//     ssr: false,
//   }
// );
// const Canvas = dynamic(
//   () => import("./index"),
//   {
//     ssr: false, 
//   }
// );

import { Helmet } from "react-helmet";

import React, { useEffect, useState ,useLayoutEffect} from "react";
import { hitApi, setMyState, assetsUrlPath, skeletonLoader } from "../../components/commonAction";
import svg from "./components/editorSvg";
import Canvas from "./components/canvas";
import { useNavigate } from "react-router-dom";

// Import editor componenets 
import { useParams } from "react-router-dom";
import Settings from "./components/editorComponent/setting";
import Drawing from "./components/editorComponent/drawing";
import EditImage from "./components/editorComponent/editImage";
import Filters from "./components/editorComponent/filters";
import ImageSetting from "./components/editorComponent/imageSetting";
import Pencil from "./components/editorComponent/pencil";
import TextSetting from "./components/editorComponent/textSetting";
import Tool from "./components/editorComponent/tool";
import { useStore } from "../../zustand/store";

import { HexColorPicker } from "react-colorful";
import { DownloadImagePopUp } from "../scheduler/components/SchedulePopUp";


const size = {
  Square: {
    width: "650px",
    height: "650px",
  },
  Landscape: {
    width: "1156px",
    height: "650px",
  },
  Portrait: {
    width: "366px",
    height: "650px",
  },
};

let NewDesign = ({ pageTitle }) => {

  const { id } = useParams();
  let navigate = useNavigate();
  let undoData = useStore((state) => state.undoData);
  let store = useStore((state) => state);
  let userData = useStore((state) => state.userData);
  let activeElement = useStore((state) => state.activeElement);
  let editorData = useStore((state) => state.editorData);
  editorData = editorData?.filter(
    (d1) => d1.type == "text" || d1.type == "image"
  );
  let filterdata = useStore(state => state.filterCanvas);

  const [editorToolType, setEditorToolType] = useState("Setting");
  const [isToolSlide, setIsToolSlide] = useState(false);
  const [loading, setloading] = useState(false);
  const [load, setload] = useState(false);
  const [check, setcheck] = useState(false);
  const [finalSpaceCharactersList, setfinalSpaceCharactersList] = useState([]);
  const [fixSize, setSize] = useState({});
  const [imageSize1, setImageSize1] = useState("");
  const [templatename, settemplatename] = useState("");
  const [settingMenu, setsettingMenu] = useState(false);
  const [process, setProcess] = useState('');
  const [cursur, setcursur] = useState(false);

  const [color, setColor] = useState("#aabbcc");
  const [downloadPopup, setDownloadPopUp] = useState(false);
  const [dwndpopupData, setDwnPopUpData] = useState({});






  useEffect(() => {

    if (id)
      getTemplatedata(id);
    return () => {
    };
  }, []);

  useEffect(() => {
    if (store.activeElement.element == "draw") {
      setEditorToolType("")
    }
  }, [store.activeElement])


  useEffect(() => {
    if (fixSize.layout) {
      setImageSize1(fixSize.layout)
    }
  }, [fixSize])


  useEffect(() => {
    if (editorToolType != "") {
      store.updateStore("selectCursor", false);
    }
  }, [editorToolType])

const checkSize=()=>{
  if(window.innerWidth<700)
  {
    setIsToolSlide(true)
  }
}


  const getTemplatedata = async (id) => {
    setloading(true);
    // let userData = useStore((state) => state.userData);
    let apiUrl;
    if (store?.userData?.role == 'admin') {
      apiUrl = "image/template/get-template"
    }
    else {
      apiUrl = "image/my-designs/get-design"
    }

    hitApi(
      {
        method: "GET",
        url: apiUrl,
        data: {
          target: id,
        },
      },
      async (resp) => {
        setloading(false);
        if (resp.data.records[0]) {

          let data = resp.data.records[0].imageData;
          let siz = data?.layout;
          if (!siz) {
            siz = "Square"
          }
          setImageSize1(siz)
          let d1 = size[siz];
          if (!d1) {
            setSize(data?.dimenstions || data?.dimension);
          } else {
            setSize(d1);
          }
          await store.updateStore("undoData", {
            status: false,
            currentPostion: 0,
            data: [],
          });
          let d2 = { ...store.drawData }
          d2.bgColor = data?.bgColor ? data?.bgColor : "linear-gradient(0deg,#FFFFFF ,#FFFFFF)"
          await store.updateStore("filterCanvas", data?.filter);
          await store.updateStore("drawData", d2);
          await store.updateStore("activetab", "");

          await store.updateStore(
            "editorData",
            data?.data == undefined
              ? []
              : data?.data?.objects == undefined
                ? []
                : data?.data?.objects
          );
          await store.updateStore("activeElement", {
            id: "",
            element: "",
          });
          settemplatename(resp.data.records[0].title)
          setload(true);
        }
        else {
          setcheck(true);
        }
      }
    );
  };


  let mangeLayersSlideRight = () => {
    setIsToolSlide(!isToolSlide);
    setsettingMenu(false)
  };

  const selectAccount = (name) => {
    setEditorToolType(name);
  };

  useEffect(() => {
    store.updateStore("activeElement", {
      id: "",
      element: ""
    })
    store.updateStore("activetab", editorToolType);
  }, [editorToolType]);

  useEffect(() => {
    let d1 = store.editorData?.filter(
      (d1) => d1.type == "text" || d1.type == "image"
    );
    if (d1.length != finalSpaceCharactersList.length) {
      setfinalSpaceCharactersList(d1);
    }
  }, [store]);

  const showEditorToolComponent = () => {
    let data = [];

    if (editorToolType === "Setting") {
      if (!loading) return <Settings name={templatename} setname={settemplatename} setsize={setSize} layout={imageSize1} size={fixSize} />;
    } else if (editorToolType === "Text") {
      return <TextSetting />;
    } else if (editorToolType === "Image") {
      if (activeElement.element == "image" && !activeElement.maskS) {
        return <EditImage />
      } else {
        return <ImageSetting />
      }

    }/* else if (editorToolType === "Shape") {
      return <ShapesIcon />;
    } */else if (editorToolType === "Filter") {
      return <Filters />;
    }
    else if (editorToolType === "Design" || editorToolType === "pencil") {
      if (activeElement.id) {
        return <Drawing />
      } else {
        return <Pencil />;
      }
    }
    else {
      if (!loading) return <Settings name={templatename} setname={settemplatename} setsize={setSize} layout={imageSize1} size={fixSize} />;
    }
  };

  const handleEditorSize = (sizeType) => {
    if (sizeType === "Portrait") {
      return "Portrait"
    } else if (sizeType === "Landscape") {
      return "Landscape"
    } else if (sizeType === "Square") {

      return "Square"
    } else if (sizeType === "Custom") {

      return "customratio"
    }
  }


  let CSS = {
    width: parseInt(fixSize.width),
    height: parseInt(fixSize.height),
    overflow: "hidden",
    background: store.drawData.bgColor,
  }
  if (filterdata && Object.keys(filterdata).length != 0) {
    CSS = {
      ...CSS,
      ...filterdata.value
    }
  }






  return (

    <>

      <Helmet >
        <title>{pageTitle}</title>
      </Helmet>
      {check == true ? (
        <>
          <h1>Page Not found</h1>
        </>
      ) : (

        <div className="cun_img_editor_bg">
          {/* <a href={`/imagePreview/${id}?type=design`}>preview</a> */}
          <div className="conatiner-fluid ">
            <div className="row  py-3 pxl-editor-row-py3-none">
              {/* <div className="col-lg-3 ">
                <div className="cun_image_editor_heading_text">
                  <a onClick={() => { navigate(-1) }} className="cun_editor-backbtn"> <span><img src="/assets/images/image-editor/icons/left-arrow.svg" alt="" /></span>Back</a>
                  {loading ? skeletonLoader('asset', 1, 84, 45) : <p className="cun_editor_tittle_name_md">{templatename}</p>}

                </div>
              </div> */}
              {/* <div className="col-xl-6 col-lg-5 col-12 order-lg-2 order-3">
                <p className="cun_editor_tittle_name_lg">{templatename}</p>
                <div className="cun_editor_tool_box_header">
                  <div className="cun_img_editor_main_box_tool_div">
                    <div className="cun_img_editor_tool_box ">
                      <div
                        className="cun_img_editor_div"
                      >
                        <div
                          className={`cun_img_editor_tool_item_box ${editorToolType === "Setting"
                            ? "cun_img_editor_active"
                            : ""
                            }`}
                          onClick={() => selectAccount("Setting")}
                        >

                          {svg.app.Equalizer_icon}
                          <h6 className="mr-auto">Settings</h6>
                        </div>
                        <span className="cun_tooltipEle">Settings</span>
                      </div>

                      <div className="cun_img_editor_div">
                        <div id="SelectArrow"
                          className={`cun_img_editor_tool_item_box  ${cursur == "true"
                            ? "cun_img_editor_active"
                            : ""
                            }`}
                          onClick={() => {
                            selectAccount("")
                            store.updateStore("selectCursor", true);
                          }}
                        >
                          <div className="cun_img_editor_tool_svg_text">
                            {svg.app.select_icon}

                          </div>

                        </div>
                        <span className="cun_tooltipEle">Select</span>
                      </div>

                      <div
                        className="cun_img_editor_div"

                      >
                        <div
                          className={`cun_img_editor_tool_item_box  ${editorToolType === "Text"
                            ? "cun_img_editor_active"
                            : ""
                            }`}
                          id="TextTab"
                          onClick={() => selectAccount("Text")}
                        >
                          <div className="cun_img_editor_tool_svg_text">
                            {svg.app.editor_textT}
                            <h6 className="mr-auto ">text</h6>
                          </div>

                        </div>
                        <span className="cun_tooltipEle">Text</span>
                      </div>
                      <div
                        className="cun_img_editor_div"
                      >
                        <div
                          className={`cun_img_editor_tool_item_box  ${editorToolType === "Image"
                            ? "cun_img_editor_active"
                            : ""
                            }`}
                          id="ImageTab"
                          onClick={() => selectAccount("Image")}
                        >
                          <div className="cun_img_editor_tool_svg_text">
                            {svg.app.editor_img}
                            <h6 className="mr-auto">Image</h6>
                          </div>

                        </div>
                        <span className="cun_tooltipEle">Images</span>
                      </div>

                      <div className="cun_img_editor_div">
                        <div
                          className={`cun_img_editor_tool_item_box  ${editorToolType === "Filter"
                            ? "cun_img_editor_active"
                            : ""
                            }`}
                          onClick={() => selectAccount("Filter")}
                        >
                          <div className="cun_img_editor_tool_svg_text">
                            {svg.app.editor_filter}

                          </div>

                        </div>
                        <span className="cun_tooltipEle">Filter</span>
                      </div>

                      <div className="cun_img_editor_div cun_editor_dropbox_option" >
                        <div
                          className={`cun_img_editor_tool_item_box  ${(editorToolType === "Drawing" || editorToolType === "pencil" || editorToolType === "Design")
                            ? "cun_img_editor_active"
                            : ""
                            }`}
                          onClick={() => selectAccount("Drawing")}
                        >
                          <div className="cun_img_editor_tool_svg_text">
                            {svg.app.editor_drawing}
                          </div>

                        </div>
                        {editorToolType === "Drawing" ? <div className="cun_editor_hover_dropdown_box">
                          <div className="cun_editor_hover_dropdown" onClick={() => {
                            store.updateStore("selectCursor", false);
                            selectAccount("pencil")
                          }}>{svg.app.editor_pencil}  <span className="cun_tooltipEle">Pencil</span></div>
                          <div className="cun_editor_hover_dropdown" onClick={() => {
                            store.updateStore("selectCursor", false);
                            selectAccount("Design")
                          }}>{svg.app.Edit_line_icon} <span className="cun_tooltipEle">Line</span></div>

                        </div> : ""}
                        <span className="cun_tooltipEle">Drawing</span>
                      </div>

                      <div
                        className="cun_img_editor_div"
                      >
                        {undoData.data.length > 0 ? (
                          <>
                            {(undoData.currentPostion > 1 && undoData.data[undoData.currentPostion - 1] != undefined) && (
                              <button
                                className="cun_img_editor_tool_item_box"
                                id="Delete"
                                onClick={() => {

                                  let data = { ...undoData };
                                  data.status = true;
                                  data.currentPostion = data.currentPostion - 1;

                                  store.updateStore("activeElement", {
                                    id: "",
                                    element: ""
                                  })
                                  store.updateStore("undoData", data);


                                }}
                              >
                                <div className="cun_img_editor_tool_svg_text">
                                  {svg.app.Undu_btn}
                                  <span className="cun_tooltipEle">Undo</span>
                                </div>


                              </button>
                            )}
                            {(undoData.currentPostion < 10 && undoData.data[undoData.currentPostion] != undefined) && (
                              <button
                                className="cun_img_editor_tool_item_box "
                                id="duplicateIcon"
                                onClick={() => {
                                  let data = { ...undoData };
                                  data.status = true;
                                  data.currentPostion = data.currentPostion + 1;
                                  store.updateStore("activeElement", {
                                    id: "",
                                    element: ""
                                  })
                                  store.updateStore("undoData", data);

                                }}
                              >
                                <div className="cun_img_editor_tool_svg_text">
                                  {svg.app.Redu_btn}
                                  <span className="cun_tooltipEle">Redo</span>
                                </div>


                              </button>
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </div>

                    </div>
                  </div>
                </div>

              </div> */}

            </div>

            <div className="cun_image_editor_main_box">
              <div className="">
                {/* <p className="cun_editor_tittle_name_lg">{templatename}</p> */}
                <div className="cun_editor_tool_box_header">
                  <div className="cun_img_editor_main_box_tool_div">
                    <div className="cun_image_editor_heading_text cun-img-editor-head-desktop">
                      <a onClick={() => { navigate(-1) }} className="cun_editor-backbtn"> <span><img src="/assets/images/image-editor/icons/left-arrow.svg" alt="" /></span>Back</a>
                      {loading ? skeletonLoader('asset', 1, 84, 45) : <p className="cun_editor_tittle_name_md">{templatename}</p>}

                    </div>
                    <div className={`cun_img_editor_tool_box  ${settingMenu ? 'settingSidebarOpen' : ''}`}>
                    <div className="cun_image_editor_heading_text cun-img-editor-head-responsive">
                      <a onClick={() => { navigate(-1) }} className="cun_editor-backbtn"> <span><img src="/assets/images/image-editor/icons/left-arrow.svg" alt="" /></span>Back</a>
                      {loading ? skeletonLoader('asset', 1, 84, 45) : <p className="cun_editor_tittle_name_md">{templatename}</p>}

                    </div>
                      <div className="cun-img-editor-left-res">

                        <a onClick={() => { setsettingMenu(!settingMenu); setIsToolSlide(false) }}>
                          {svg.app.leftArrow}
                        </a>
                      </div>
                      <div className="cun-editor-settingInner">
                        <div className="cun_img_editor_div">
                          <div
                            className={`cun_img_editor_tool_item_box ${editorToolType === "Setting"
                              ? "cun_img_editor_active"
                              : ""
                              }`}
                            onClick={() => { selectAccount("Setting"); setcursur(false); checkSize()}}
                          >


                            <h6 className="mr-auto"><span><img src="/assets/images/image-editor/icons/setting.svg" alt="" /></span>Settings</h6>
                          </div>

                        </div>

                        <div className="cun_img_editor_div">
                          <div id="SelectArrow"
                            className={`cun_img_editor_tool_item_box  ${cursur
                              ? "cun_img_editor_active"
                              : ""
                              }`}
                            onClick={() => {
                              selectAccount("");
                              setcursur(true);
                              store.updateStore("selectCursor", true);
                            }}
                          >
                            <h6 className="mr-auto"><span><img src="/assets/images/image-editor/icons/select.svg" alt="" /></span>select</h6>
                          </div>
                          {/* <span className="cun_tooltipEle">Select</span> */}
                        </div>

                        <div className="cun_img_editor_div" >
                          <div
                            className={`cun_img_editor_tool_item_box  ${editorToolType === "Text"
                              ? "cun_img_editor_active"
                              : ""
                              }`}
                            id="TextTab"
                            onClick={() => { selectAccount("Text"); setcursur(false);checkSize() }}
                          >
                            <h6 className="mr-auto "><span><img src="/assets/images/image-editor/icons/text.svg" alt="" /></span>text</h6>


                          </div>
                        </div>
                        <div
                          className="cun_img_editor_div"
                        >
                          <div
                            className={`cun_img_editor_tool_item_box  ${editorToolType === "Image"
                              ? "cun_img_editor_active"
                              : ""
                              }`}
                            id="ImageTab"
                            onClick={() => { selectAccount("Image"); setcursur(false);checkSize() }}
                          >
                            <h6 className="mr-auto"><span><img src="/assets/images/image-editor/icons/image.svg" alt="" /></span>Image</h6>
                          </div>

                        </div>

                        <div className="cun_img_editor_div">
                          <div
                            className={`cun_img_editor_tool_item_box  ${editorToolType === "Filter"
                              ? "cun_img_editor_active"
                              : ""
                              }`}
                            onClick={() => { selectAccount("Filter"); setcursur(false);checkSize() }}
                          >
                            <h6 className="mr-auto"><span><img src="/assets/images/image-editor/icons/filter.svg" alt="" /></span>filter</h6>

                          </div>

                        </div>

                        <div className="cun_img_editor_div cun_editor_dropbox_option" >
                          <div
                            className={`cun_img_editor_tool_item_box  ${(editorToolType === "Drawing" || editorToolType === "pencil" || editorToolType === "Design")
                              ? "cun_img_editor_active"
                              : ""
                              }`}
                            onClick={() => { selectAccount("Drawing"); setcursur(false);checkSize() }}
                          >
                            <h6 className="schDrawing"><span><img src="/assets/images/image-editor/icons/drawing.svg" alt="" /></span>drawing <span className="sch-drawingArrow"><img src="/assets/images/image-editor/icons/down-arrow.svg" alt="" /></span></h6>

                          </div>
                          {editorToolType === "Drawing" ? <div className="cun-drawingMenu">
                            <ul>
                              <li>
                                <a onClick={() => {
                                  store.updateStore("selectCursor", false);
                                  selectAccount("pencil");checkSize();
                                }}>
                                  <span><img src="/assets/images/image-editor/icons/pencil.svg" alt="" /></span>pencil
                                </a>
                              </li>
                              <li>
                                <a onClick={() => {
                                  store.updateStore("selectCursor", false);
                                  selectAccount("Design");checkSize();
                                }}>
                                  <span><img src="/assets/images/image-editor/icons/line.svg" alt="" /></span>line
                                </a>
                              </li>
                            </ul>
                            {/* <div className="cun_editor_hover_dropdown" onClick={() => {
                            store.updateStore("selectCursor", false);
                            selectAccount("pencil")
                          }}>{svg.app.editor_pencil}  <span className="cun_tooltipEle"><span><img src="/assets/images/image-editor/icons/pencil.svg" alt=""/></span>Pencil</span>
                          </div>

                          <div className="cun_editor_hover_dropdown" onClick={() => {
                            store.updateStore("selectCursor", false);
                            selectAccount("Design")
                          }}><span><img src="/assets/images/image-editor/icons/line.svg" alt=""/></span>Line
                          </div> */}

                          </div> : ""}
                        </div>

                        {/* <div
                          className="cun_img_editor_div"
                        >
                          {undoData.data.length > 0 ? (
                            <>
                              {(undoData.currentPostion > 1 && undoData.data[undoData.currentPostion - 1] != undefined) && (
                                <div className="cun_img_editor_tool_item_box  " id="Delete"
                                  onClick={() => {

                                    let data = { ...undoData };
                                    data.status = true;
                                    data.currentPostion = data.currentPostion - 1;

                                    store.updateStore("activeElement", {
                                      id: "",
                                      element: ""
                                    })
                                    store.updateStore("undoData", data);
                                  }}
                                >




                                  <h6><span><img src="/assets/images/image-editor/icons/undo.svg" /></span>
                                  
                                    Undo</h6>
                                </div>
                              )}
                              {(undoData.currentPostion < 10 && undoData.data[undoData.currentPostion] != undefined) && (
                                <div className="cun_img_editor_tool_item_box "
                                  id="duplicateIcon"
                                  onClick={() => {
                                    let data = { ...undoData };
                                    data.status = true;
                                    data.currentPostion = data.currentPostion + 1;
                                    store.updateStore("activeElement", {
                                      id: "",
                                      element: ""
                                    })
                                    store.updateStore("undoData", data);

                                  }}>
                                  <h6>  <span><img src="/assets/images/image-editor/icons/redo.svg" /></span>Redo</h6>

                                </div>
                              )}

                            </>
                          ) : (
                            <></>
                          )}
                        </div> */}
                      </div>

                    </div>
                  </div>
                </div>

              </div>


              <div className="cun-canvas-mainwrapper">
              {/* <div className="cun-canva-undo-redo-btnWrap">
                    {undoData.data.length > 0 ? (
                    <ul>
                            <>
                              {(undoData.currentPostion > 1 && undoData.data[undoData.currentPostion - 1] != undefined) && (
                      <li onClick={() => {
                        let data = { ...undoData };
                        data.status = true;
                        data.currentPostion = data.currentPostion - 1;
                        store.updateStore("activeElement", {
                          id: "",
                          element: ""
                        })
                        store.updateStore("undoData", data);
                      }}>
                        <a >
                        <img src="/assets/images/image-editor/icons/undo.svg" />
                        <span>Undo</span>
                        </a>
                      </li>
                              )}
                {(undoData.currentPostion < 10 && undoData.data[undoData.currentPostion] != undefined) && (
                      <li  onClick={() => {
                        let data = { ...undoData };
                        data.status = true;
                        data.currentPostion = data.currentPostion + 1;
                        store.updateStore("activeElement", {
                          id: "",
                          element: ""
                        })
                        store.updateStore("undoData", data);

                      }} >
                        <a >
                        <img src="/assets/images/image-editor/icons/redo.svg" />
                        <span>Redo</span>
                        </a>
                      </li>
  )}

</>
</ul>

) :""}
         </div>             */}
                  
                <div className="cun_edit_toolbox_height">
                  {/* {(activeElement.element == "image" || activeElement.element == "textbox" || activeElement.element == "draw") ? ( */}
                    <div className="cun_img_editor_main_box_tool pxl-toolbox-desktop">
                      <Tool />
                    </div>
                  {/* ) : (
                    <></>
                  )} */}

                </div>
                <div className="cun_canvasHolder" style={{ overflow: "hidden" }}>

                  {loading ? <div className="loaderCenter">{skeletonLoader('asset', 1, 650, 650)}</div> : load == true ? (
                    <div
                      className={` cun_img_editor_main_box ${handleEditorSize(imageSize1)}`.concat(store.drawData.bgColor.includes("url") ? " bgfullImage" : "")}
                      style={CSS}
                    >
                      <Canvas layout={handleEditorSize(imageSize1)} editorToolType={editorToolType} dimension={fixSize} onProcess={(process) => {
                        setProcess(process);
                      }} downloadData={dwndpopupData} />

                    </div>
                  ) : ('')}
                </div>
                <div className="cun_image_editor_btn_box">

                  <button id="CreateGroup1"
                    className="cun_btn space_nowrap cun_hidebtn"
                    onClick={() => {
                      let t1 = document.getElementById("CreateGroup")
                      if (t1) {
                        t1.click()
                      }
                    }}
                  >
                    Create Group
                  </button>

                  <button
                    id="UnGroup1"
                    onClick={() => {
                      let t1 = document.getElementById("UnGroup")
                      if (t1) {
                        t1.click()
                      }
                    }}
                    className="cun_btn space_nowrap cun_hidebtn"
                  >
                    UnGroup
                  </button>

                  <button
                    disabled={process == 'save' ? true : false}
                    className="cun_btn"
                    onClick={() => {
                      store.updateStore("save", 1);
                    }}
                  >
                    {" "}
                    {svg.app.saveIcon} {process == 'save' ? 'Processing...' : 'Save'}{" "}
                  </button>
                 
                  {(store.userData.role == 'user' || store.userData.role == 'teamMember') &&
                    <button
                      disabled={process == 'download' ? true : false}
                      onClick={() => {
                        setDownloadPopUp(true);
                      }}
                      className="cun_addAccBtn_blk"
                    >
                      {" "}
                      {svg.app.downloadIcon}  {process == 'download' ? 'Processing...' : 'Download'}
                    </button>}

                  {/* // <button
  //   onClick={() => {
  //     store.updateStore("save", 2);
  //   }}
  //   className="cun_addAccBtn_blk"
  // >
  //   {" "}
  //   {svg.app.publish_icon} Publish
  // </button> */}

                </div>
                <div className="cun_img_editor_main_box_tool pxl-toolbox-responsive">
                      <Tool />
                    </div>
              </div>

              <div className="">
                {/* {editorToolType && editorToolType != "Drawing" ? ( */}

                <div
                  className={`cun_editor_platform_bg  ${isToolSlide
                    ? "isOpenSidebarRight"
                    : " isCloseSidebarRight"
                    }`}
                >
                  <a
                    className="cun_sidebarBtnRight"
                    onClick={(e) => mangeLayersSlideRight()}
                  >
                    {" "}
                    {isToolSlide ? <div className="cun_editor_tool_sidebar1" >{svg.app.leftArrow}</div> : <div className="cun_editor_tool_sidebar2"> Toolbar {svg.app.rightArrow}</div>}
                  </a>
                  <div className="cun_editor_platform_box">
                    <div className="cun_overflow_scroll"  id="pixastock_div">
                      {loading ? <div>{skeletonLoader('asset', 1, '100%', 650)}</div> :
                        showEditorToolComponent()
                      }
                      {/* {showEditorToolComponent()} */}

                    </div>
                  </div>
                </div>
                {/* ) : (
                  ""
                )} */}

                {activeElement.element == "draw" ? <>
                  <div
                    className={`cun_editor_platform_bg  ${isToolSlide
                      ? "isOpenSidebarRight"
                      : " isCloseSidebarRight"
                      }`}
                  >
                    <a
                      className="cun_sidebarBtnRight"
                      onClick={(e) => mangeLayersSlideRight()}
                    >
                      {" "}
                      {isToolSlide ? <div className="cun_editor_tool_sidebar1" >{svg.app.leftArrow}</div> : <div className="cun_editor_tool_sidebar2"> Toolbar {svg.app.rightArrow}</div>}
                    </a>
                    <div className="cun_editor_platform_box">
                      <div className="cun_overflow_scroll">
                        <Drawing />
                      </div>
                    </div>
                  </div>
                </> : ""}
              </div>

            </div>
          </div>
        </div>
      )}
      <DownloadImagePopUp
        shownPopup={downloadPopup}
        closePopup={() => setDownloadPopUp(false)}
        width={parseInt(fixSize.width)}
        height={parseInt(fixSize.height)}
        type={'editor'}
        setData={(val) => setDwnPopUpData(val)}
        process={process == 'download'}
        mTitle={templatename}
      />
    </>
  );
};

export default NewDesign;
