import { useEffect, useState } from "react";
import Select from "react-select";
import { assetsUrlPath, getNameInitials, hitApi, skeletonLoader } from "../commonAction";
import { useStore } from "../../zustand/store";
import Cookies from "js-cookie";
import { useNavigate, useLocation } from 'react-router-dom';

function BrandSelect() {
    const [allBrands, setAllBrands] = useState([]);
    const [brandList, setBrandList] = useState([]);
    const [selectedBrand, setSelectedBrand] = useState({});
    const [loadData, setLoadData] = useState(false)
    let store = useStore(state => state);
    const navigate = useNavigate();
    let { currentBrandId, role, scope } = store.userData;
    let appPath = useLocation().pathname.split('/').filter(Boolean)[0];
    let currentPath = useLocation().pathname.replace(`/${appPath}`, '');

    useEffect(() => {
        getBrands();
    }, [])

    const getBrands = () => {
        setLoadData(true)
        hitApi({
            url: 'brand/brand-list',
            method: 'GET',
            data: { limit: 'all' },
            loading: true,
            alert: false,
        },
            (resp, err = null) => {
                if (resp.status) {
                    setLoadData(false)
                    let data = resp.data.records;
                    setAllBrands(data);
                    if (data.length > 0) {
                        let selectdata = data.map(val => { return { value: val._id, label: val.title, path: val?.icon?.original?.path } });

                        let filterBrands = selectdata.filter(data => data.value !== currentBrandId);
                        setBrandList(filterBrands);
                        let selectBrand = data.find(val => val._id == currentBrandId);
                        setSelectedBrand({ value: currentBrandId, label: selectBrand.title, path: selectBrand?.icon?.original?.path })

                    }
                }
            });
    }

    const brandSelectFilter = (currBrandId) =>{
        let allData = [...brandList,selectedBrand];
        let filterBrands = allData.filter(data => data.value !== currBrandId);
        setBrandList(filterBrands);
        let selectBrandObj = allData.find(val => val.value == currBrandId);
        setSelectedBrand({ value: currBrandId, label: selectBrandObj.label, path: selectBrandObj?.path })
  }

    const handleSelectedAction = (val) => {

        setSelectedBrand(val)
        hitApi({
            url: 'brand/change-brand',
            method: 'PATCH',
            data: {
                brandId: val.value
            },
            alert: false,
        }, (resp, err = null) => {
            if (!err && resp.data) {
                Cookies.set('authToken', resp?.data?.token, { expires: +process.env.REACT_APP_TOKEN_LIFE })
                store.updateStore("userData", { ...store.userData, ...resp?.data });
                if (currentPath && role !== 'teamMember') {
                    brandSelectFilter(val.value);
                    navigate(`/${appPath}`);
                } else {
                    window.location.reload();
                }
            }
        });
    }

    let logoutMe = () => {
        store.updateStore("userData", {});
        Cookies.remove('authToken');
        navigate('/');
    }

    return (
        <>
            <div className='pxl-user-info-userName-box'>
                <ul>
                    <li>
                        {selectedBrand && <a href="#" onClick={(e)=>{
                            e.preventDefault()
                            }}>
                            <div className='pxl-userInfoBox'>
                                <img src={assetsUrlPath(selectedBrand?.path)} width={'30px'} height={'30px'} />
                                <p>{selectedBrand?.label}</p>
                            </div>
                            <div className='pxl-userInfo-icon'>
                                <img src="/assets/images/downSvg1.png" alt="" />
                            </div>
                            <div className="pxl-profile-brandText">
                                <h2>brand</h2>
                            </div>
                        </a>}
                        <div className="pxl-userInfoBox1">
                            <ul>
                                {loadData && [...Array(10)].map((count, index) => <span key={index}>{skeletonLoader('asset', "", '100%', 70, index)}</span>)}


                                {
                                    brandList.length > 0 && brandList.map((brand, index) => <li key={index}>
                                        <a onClick={() => handleSelectedAction(brand)}>
                                            <div className='pxl-userInfoBox'>
                                                {
                                                    brand?.path ? <img src={assetsUrlPath(brand.path)} width={'30px'} height={'30px'} /> : <div className="pxl-userName-letter">
                                                        <h2>{getNameInitials(brand?.label)}</h2>
                                                    </div>
                                                }


                                                <p>{brand.label}</p>
                                            </div>
                                            <div className='pxl-userInfo-icon'>

                                            </div>
                                        </a>
                                    </li>)
                                }

                            </ul>
                        </div>
                    </li>
                </ul>
            </div>
            <ul>
                <li>
                    <a target='_blank' href="/account-settings#profile">
                        <span>
                            <img src="/assets/images/editProfile.svg" alt="" />
                        </span>
                        Edit Profile
                    </a>
                </li>
                <li>
                    <a href="#" onClick={logoutMe} >
                        <span>
                            <img src="/assets/images/logOut.svg" alt="" />
                        </span>
                        Logout
                    </a>
                </li>
            </ul>

            {/* <Select options={brandList}
                className={'innerspan'}
                value={selectedBrand}
                onChange={(option) => handleSelectedAction(option)}
            /> */}
        </>
    )
}

export default BrandSelect