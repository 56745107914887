import React, { useState, useEffect, useRef } from 'react';
import { hitApi, setMyState, assetsUrlPath, skeletonLoader, getExtensionFromUrl, NoDataFound } from "../../commonAction";
import schedulerSvg from '../../../pages/scheduler/components/schedulerSvg';
import svg from '../../svg';
import appConfig from '../../../config/appConfig';

const ThirdPartyMedia = ({ baseClass = '' , onClickAct = null}) => {
    let appObj = [
        {
            title: "Pixabay",
            name: "pixabay",
            icon: "/assets/images/newpost/pixabay-app.png",
            status: true,
            typeList: ['image', 'video']
        },
        {
            title: "Giphy",
            name: "giphy",
            icon: "/assets/images/newpost/giphy-app.png",
            status: true
        },
        {
            title: "Pexels",
            name: "pexels",
            icon: "/assets/images/newpost/pexels-app.png",
            status: true,
            typeList: ['image', 'video']
        },
        {
            title: "Unsplash",
            name: "unsplash",
            icon: "/assets/images/newpost/unsplash.png",
            status: true
        },
        // {
        //     title: "YT thumb",
        //     name: "yt-thumb",
        //     icon: "/assets/images/newpost/yt-thumb.png",
        //     status: false
        // },
        {
            title: "My Design",
            name: "pixaDesign",
            icon: "/assets/images/newpost/app-my-design.png",
            status: true,
            typeList: ['image','templales']
        },
    ]
    const [state, setState] = useState({
        activeApp: false,
        limit: 9,
        page: 1,
        isLoading: false,
        mediaArray: [],
        totalCount: 0,

        loadData: false,
        mediaType: 'image',
        playMedia: false,
        isSearched : 'init'
    });

    let loadMediaData = () => {
      
        let { mediaType, limit, page, keyword, activeApp, mediaArray } = state;
        if ((['pixabay', 'pixaDesign'].includes(activeApp.name)) || keyword) {
            setMyState(setState, { isLoading: true });

            let tUrl = activeApp.name == 'pixaDesign' ? (mediaType == 'image' ? 'image/my-designs/get-design?type=image' : "image/template/get-image-template?type=image") : 'user-assets/filter-media';
            hitApi({
                url: tUrl,
                method: "GET",
                data: {
                    source: activeApp.name,
                    mediaType, limit, page, keyword
                }
            }, (resp, err = null) => {
                setMyState(setState, { 
                    isLoading: false, 
                    loadData: false,
                    isSearched : true 
                })
                if (!err) {
                    let data = activeApp.name == 'pixaDesign' ? resp.data.records : resp.data;
                    setMyState(setState, {
                        mediaArray: page > 1 ? [...mediaArray, ...data] : data,
                        totalCount: resp.totalRecords
                    })
                }
            })
        }
    }

    useEffect(() => {
        if (state.activeApp) {
            if (state.loadData) {
                loadMediaData();
            }
        } else {
            setMyState(setState, {
                isLoading: false,
                mediaArray: [],
                totalCount: 0,
                mediaType: 'image',
                keyword: '',
                isSearched : 'init'
            });
        }
    }, [state.activeApp, state.loadData]); 


    let getMediaObj = (media) => {
    
        let source = state.activeApp.name,
            { mediaType } = state,
            mediaObj = {
                id: media.id || null,
                type: mediaType,
                title: '',
                tag: [],
                mimeType: ''
            };
         
        if (source == 'giphy') {
            mediaObj = {
                ...mediaObj,
                path: media?.images?.original?.url,
                title: media.title,
                mimeType: `image/gif`
            };
        } else if (source == 'pixabay') {
            mediaObj = {
                ...mediaObj,
                tag: media.tags.split(', '),
            };

            if (mediaType == 'image') { 
                mediaObj = {
                    ...mediaObj,
                    thumb: media.previewURL,
                    path: media.largeImageURL,
                    mimeType: `image/${getExtensionFromUrl(media.largeImageURL)}`,
                };
            } else {
                let getVid = media.videos.medium;
                mediaObj = {
                    ...mediaObj,
                    thumb: `https://i.vimeocdn.com/video/${media.picture_id}_${getVid.width}x${getVid.height}.jpg`,
                    path: getVid.url,
                    mimeType: `video/${getExtensionFromUrl(getVid.url)}`,
                };
            }

        } else if (source == 'pexels') {
            mediaObj = {
                ...mediaObj,
                title: media.alt,
            };

            if (mediaType == 'image') {
                mediaObj = {
                    ...mediaObj,
                    thumb: media.src.small,
                    path: media.src.original,
                    mimeType : `image/${getExtensionFromUrl(media.src.original)}`,
                };
            } else {
                let getVid = media.video_files.filter(d => d.file_type == 'video/mp4' && d.width <= 1920);
                mediaObj = {
                    ...mediaObj,
                    mimeType: 'video/mp4',
                    thumb: media.image,
                    path: media.image
                };
            }

        } else if (source == 'unsplash') {
            mediaObj = {
                ...mediaObj,
                thumb: media?.urls?.thumb,
                path: media?.urls?.regular,
                title: media.alt_description,
                mimeType: `image/jpeg`
            };
        }else if(source == 'pixaDesign'){
            if(media.files){
                mediaObj = {
                    ...mediaObj,
                    id : media._id,
                    type : 'image',
                    title : media.title,
                    mimeType: 'image/jpeg',
                    thumb: appConfig.cdnPath+"medias/"+media.files.original.thumb,
                    path: appConfig.cdnPath+"medias/"+media.files.original.path,
                    original : media.files.original
                };
            }
        }
        return mediaObj;
    }

    const addToMyLibrary = (obj) => {
        
        hitApi({
            url: 'user-assets/add-media-in-library',
            method: "POST",
            data: {
                source: state.activeApp.name,
                mediaType: obj.type,
                sourceId: obj.id,
                url: obj.path,
                mimeType: obj.mimeType,
                tags: obj.tag.join(','),
                title: obj.title
            }
        }, (resp, err = null) => {
            if (!err) {

            }
        });
    }

    return (
        <>
            <div className={`${baseClass}`}>
                {
                    !state.activeApp ?
                        <div className="sch-apps-wrapper schsortPage-apps">
                            <h2 className="sch-textfont16">Apps</h2>
                            <ul className="sch-apps-flex">
                                {
                                    appObj.map((app,index) => {
                                        return <li key={index}>
                                            <a className={`${!app.status ? 'pxl-button-disable' : ''}`} onClick={e => setMyState(setState, {
                                                activeApp: app,
                                                // loadData: !state.loadData
                                                loadData: (['pixabay', 'pixaDesign'].includes(app.name)) ? true : false
                                            })}>
                                                <img src={app.icon} alt="" />
                                                <p>{app.title}</p>
                                            </a>
                                        </li>
                                    })
                                }

                            </ul>
                        </div>

                        :
                        <div className='sch-apps-wrapper'>
                            <div className='sch-backBtn'>
                                <a onClick={e => setMyState(setState, { activeApp: false })} ><span>{schedulerSvg.app.leftArrow} {state.activeApp.title}</span> </a>
                            </div>

                            <div className="sch-search-section">
                                <div className={`sch-search-row sch-search-bg-color`}>
                                    <input
                                        placeholder={`Search ${state.mediaType.toLocaleLowerCase()}s`}
                                        value={state.keyword}
                                        onChange={e => {
                                            setMyState(setState, { 
                                                keyword: e.target.value,
                                                isSearched : false 
                                            });
                                        }}
                                        onKeyUp={async e => {
                                            e.preventDefault();
                                            if (e.key === 'Enter') {
                                    
                                                setMyState(setState, {
                                                    page: 1,
                                                    mediaArray: [],
                                                    totalCount: 0,
                                                    loadData: !state.loadData
                                                });
                                            }
                                        }}
                                    />
                                    <button type="button" className="sch-search-btn">
                                        <img src="/assets/images/scheduler/search.svg" />
                                    </button>
                                </div>
                            </div>
                            {
                                state?.activeApp?.typeList && state?.activeApp?.typeList.length ?
                                    <div className='sch-selectOption-wrap'>

                                        {/* <select value={state.mediaType} onChange={e => {
                                            setMyState(setState, {
                                                mediaType : e.target.value,
                                                mediaArray : [],
                                                page : 1,
                                                totalCount : 0,
                                                loadData : true
                                            })
                                        }}>
                                        {
                                            state?.activeApp?.typeList.map(d => {
                                                return <option value={d} >{d}</option>
                                            })
                                        }
                                    </select> */}

                                        <div className="cun-radiobox-wrapper">
                                            {state?.activeApp?.typeList.map((option,index) => (
                                                <div key={index} className="cun-custom-radioBtn">
                                                    <input type="radio" id={option} value={state.mediaType} checked={state.mediaType === option} name={'media_type'} onChange={(e) => setMyState(setState, {
                                                        mediaType: option,
                                                        mediaArray: [],
                                                        page: 1,
                                                        totalCount: 0,
                                                        loadData: true
                                                    })} />
                                                    <label htmlFor={option}>{option}</label>
                                                </div>
                                            ))
                                            }
                                        </div>
                                    </div>
                                    : <></>
                            }
                            
                            <div className="sch-post-img-wrapper">
                                <div className="sch-post-images" >
                                    {
                                        !state.mediaArray.length && !state.isLoading && <>
                                        <NoDataFound title={!state.isSearched || state.isSearched == 'init' ? 'Please enter keyword and hit enter to search.' : 'Data not found.'}/>
                                            {/* <p>{!state.isSearched || state.isSearched == 'init' ? 'Please enter keyword and hit enter to search.' : 'Data not found.'}</p> */}
                                        </>
                                    }

                                    {state.mediaArray.length == 0 && state.isLoading ?
                                        [...Array(9)].map((i,index) => <div className="sch-post-image-item" key={index}>{skeletonLoader('asset', 1, 109, 102)}</div>) :

                                        Array.isArray(state.mediaArray) && state.mediaArray.length ?
                                            state.mediaArray.map((item,index) => {
                                                let mObj = getMediaObj(item);

                                                return <div className="sch-post-image-item" key={index}
                                                    onMouseEnter={e => {
                                                        if (mObj.type != 'image') {
                                                            setMyState(setState, {
                                                                playMedia: mObj.id
                                                            })
                                                        }
                                                    }}
                                                >

                                                    <div className='pxl-ImgBox'>
                                                        {
                                                            state.playMedia == mObj.id ?
                                                                <video
                                                                    preload="none"
                                                                    src={mObj.path}
                                                                    autoPlay={true}
                                                                    muted={true}
                                                                    playsInline disablePictureInPicture
                                                                    onMouseLeave={e => {
                                                                        setMyState(setState, {
                                                                            playMedia: null
                                                                        })
                                                                    }}
                                                                />
                                                                :
                                                                <img
                                                                    src={mObj.thumb || mObj.path}
                                                                    onClick={() => {
                                                                        let {name} = state.activeApp;
                                                                        if(onClickAct && name == 'pixaDesign'){
                                                                            onClickAct(mObj);
                                                                        }
                                                                    }}
                                                                />
                                                        }
                                                    </div>
                                                    {
                                                        state.activeApp.name != 'pixaDesign' &&  <a className='sch-iconSec' onClick={() => addToMyLibrary(mObj)}>{svg.app.Addsvg} </a>
                                                    }
                                                    {/* <p>{mObj.title}</p> */}

                                                </div>
                                            })
                                            : <></>
                                    }


                                </div>
                            </div>
                        </div>
                }
                <div className='loadmorebtn'>
                    {
                        Array.isArray(state.mediaArray) && !state.mediaArray.length && state.isLoading && <p>Loading...</p>
                    }
                    {
                        Array.isArray(state.mediaArray) && state.mediaArray.length < state.totalCount &&
                        <a className='sch-loadmoreBtn ' onClick={e => {
                            setMyState(setState, {
                                page: +state.page + 1,
                                loadData: true
                            })
                        }}>{state.isLoading ? <span>Loading...</span> : <span>Load More</span>}</a>
                    }
                </div>
            </div>
        </>
    )
}
export default ThirdPartyMedia;