import React, { useState, useEffect, useRef } from 'react';
import { hitApi, setMyState, assetsUrlPath, teamMemberRestrictionMess } from "../../commonAction";
import { useLinkedIn } from "react-linkedin-login-oauth2";
import schedulerSvg from '../../../pages/scheduler/components/schedulerSvg';

let GoogleBtn = ({title = 'Add New Account',  parent = "social",containsSchedularWrite,load}) => {


    const linkedinlogin = () => {

        hitApi({
            url: 'social-account/get-google-auth-link',
            method: "GET",
            data: {}
        }, (resp, err = null) => {
            if (resp) {
                var openWindow = window.open(resp.data.url, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
            
                // check popup is closed or not with interval.
                var popupTick = setInterval(function() {
                    if (openWindow.closed) {
                        clearInterval(popupTick);
                        setTimeout(() => {
                            load(s1=>!s1)
                            // _this.handleError(e, type);
                        }, 200);
                    }
                }, 500);
            }
        });

        
    }
    
    return(
        <>
        	{parent == 'socialAccComponent' ?
				<a  onClick={linkedinlogin}>
					<div className="sch-user-img sch-user-img-add">
						{schedulerSvg.app.addNewSvg}
					</div>
					<div className="sch-user-name">
						<h2>{title}</h2>
					</div>
				</a>
				:
                parent == 'reconnect' ?
                <div className='cun-socialIcon' >
                   <button className={`cun-socialIcon  pxl-tooltip-button-form pxl-reload-tooltip ${containsSchedularWrite ? "" : 'pxl-tooltip-btn-diable'}`} onClick={linkedinlogin}
                    disabled={!containsSchedularWrite}>
						 <img src="/assets/images/social/reconnect.svg" alt=""/>
					</button>
					<div className="cun-show-tooltip">{containsSchedularWrite ? <p>Reconnect</p> : teamMemberRestrictionMess(containsSchedularWrite)}
					</div>     
                </div>
				// <div onClick={linkedinlogin}>reconnect</div>
				:
                <div className='pxl-add-new-accountBtn cun-socialIcon'> 
                <button className={`pxlCommon-btn ${containsSchedularWrite ? "" : 'sch-btn-disbale'}`}  disabled={!containsSchedularWrite}  onClick={linkedinlogin}>{title}</button> 
                {!containsSchedularWrite &&  <div className="cun-show-tooltip"><p>{teamMemberRestrictionMess(containsSchedularWrite)}</p></div>}
                </div>
			}
          
        </>
    );
}

export default GoogleBtn;