import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useEffect, useRef, useState } from "react";
import { Modal } from 'react-bootstrap';
import { NoDataFound, assetsUrlPath, commonToast, dateFormatter, hitApi, setMyState, skeletonLoader, useOutsideClick } from "../../components/commonAction";
import ConfirmationPopup from "../../components/common/ConfirmationPopup";
import svg from "../../components/svg";
import { useStore } from "../../zustand/store";
import Select from "react-select";


const options = [
    { value: '', label: 'Select Action' },
    { value: 'delete', label: 'Delete' },
]
const Projects = ({ pageTitle, isTrash = false, searchType, mediaKeyWord, displayButtons, action, updateAction, checkAll = false, chackAllAction = false, checkType = false }) => {

    const [showModal, setShowModal] = useState(false);
    const [polderList, setProFolderList] = useState([]);
    let store = useStore(state => state);

    let { role, scope } = store.userData;

    const handleShow = (item) => {
        setShowModal(true);
        if (item) {
            setMyState(setState, { title: item.title, targetId: item._id })
        }
    }
    const [state, setState] = useState({
        title: '',
        targetId: '',
        isProcessing: false,
        limit: 10,
        page: 1,
        sort: 'createdAt=-1',
        keyword: '',
        isLoading: false,
        totalCount: 0,
        loadData: false,
        isDataload: false,
        removeMe: [],
        restoreMe: [],
        isLoad: false,
        loadMoreActive: false,
        totalRecord: '',
        newDesignPopUp: false,
        selectedFolder: [],
        localCheckAll: '',
        clearKeyword: '',
        checkLocalType: false,
        previewData:{},
        previewPopUp:false,
        hideSelectAll:false
    });

    const handleClose = () => { setShowModal(false); setMyState(setState, { targetId: '', title: '' }) };

    const clearSearch = () => {
        setMyState(setState, { ...state, clearKeyword: !state.clearKeyword, keyword: '' });
    }

    const [myProjectFolderList, setmyProjectFolderList] = useState([]);
    useEffect(() => {
        getMyProject();
    }, [state.isLoad, searchType, state.clearKeyword])

    useEffect(() => {

        if (action == 'Delete') {
            setMyState(setState, { removeMe: state.selectedFolder })
        } else if (action == 'Restore') {
            setMyState(setState, { restoreMe: state.selectedFolder })
        }

    }, [action])

    useEffect(() => {
        if (isTrash) {

            if (checkAll) {
                setMyState(setState, { selectedFolder: myProjectFolderList });
                displayButtons(myProjectFolderList.length)
            } else {
                if (checkType) {
                    setMyState(setState, { selectedFolder: [] });
                    displayButtons(0)
                }
            }
        }
    }, [checkAll])
    useEffect(() => {

        if (checkAll) {
            setMyState(setState, { selectedFolder: myProjectFolderList.map(val => val._id) });
            displayButtons(myProjectFolderList.length)
        } else {
            if (checkType) {
                setMyState(setState, { selectedFolder: [] });
                displayButtons(0)
            }
        }

    }, [checkAll])


    useEffect(() => {
        if (isTrash) {
            if (myProjectFolderList?.length > 0) {
                if (myProjectFolderList?.length == state.selectedFolder?.length) {
                    chackAllAction(true)
                } else {
                    chackAllAction(false)
                }
            }
        }
    }, [state.selectedFolder])

    useEffect(() => {

        if (!state.checkLocalType) {
            if (myProjectFolderList?.length > 0) {
                if (myProjectFolderList?.length - 1 == state.selectedFolder?.length) {
                    setMyState(setState, { localCheckAll: true })
                } else {
                    setMyState(setState, { localCheckAll: false })
                }
            }
        }
    }, [state.selectedFolder])

    const getMyProject = (type = null) => {
        let { limit, page, sort, keyword } = state,
            cPage = type === 'loadMore' ? page + 1 : page;
        let reqData = { limit, page: cPage, isDesigns: 1, sort, keyword: searchType ? mediaKeyWord : keyword };

        if (isTrash) {
            reqData.isTrash = true;
        }

        setMyState(setState, { isLoading: true, loadMoreActive: type == 'loadMore' ? true : false,page: cPage });
        hitApi({
            url: 'image/folder/get-folders',
            method: "GET",
            data: reqData
        }, (resp, err = null) => {
            setMyState(setState, { isLoading: false });

            if (resp.status) {
                let newList = type == 'loadMore' ? [...myProjectFolderList, ...resp.data.records] : resp.data.records;
                setmyProjectFolderList(newList)
                // setProFolderList(newList.shift())
                setMyState(setState, { totalRecord: resp.data.totalRecords });
            }
        });
    }


    const handleSelectProject = (val, folder) => {

        if (val) {
            setMyState(setState, { selectedFolder: [...state.selectedFolder, folder._id] })
            isTrash && displayButtons([...state.selectedFolder, folder].length)
        } else {
            const arrCopy = Array.from(state.selectedFolder);
            const objWithIdIndex = arrCopy.findIndex((obj) => obj === folder._id);

            arrCopy.splice(objWithIdIndex, 1);
            setMyState(setState, { selectedFolder: arrCopy })
            isTrash && displayButtons(arrCopy.length);
        }
    }

    const handleCreate = (e) => {
        e.preventDefault();
        if (!state.title.trim()) {
            commonToast('error', `Project is required.`);
            return false;
        }
        setMyState(setState, { isProcessing: true })
        let data = { title: state.title };
        if (state.targetId) {
            data['target'] = [state.targetId]
        }

        hitApi({
            url: state.targetId ? 'image/folder/update-folder' : 'image/folder/create-new',
            method: state.targetId ? "PATCH" : "POST",
            data: data
        }, (resp, err = null) => {

            setMyState(setState, { isProcessing: false });

            if (resp.status) {
                setMyState(setState, { isProcessing: false, targetId: '', title: '' });
                setShowModal(false);
                getMyProject();
            }

        });
    }


    const actionRef = useRef();


    useOutsideClick(actionRef, () => setallsubmenu(false));

    const [allsubmenu, setallsubmenu] = useState(false);
    const toggleSublist = (id) => {
        setallsubmenu(prevState => ({
            //   ...prevState,
            [id]: !prevState[id]
        }));
    };
    const containsWrite = (role === "teamMember") ? scope.images.images.includes("write") : (role === 'client') ? false : true;
    const containsImagesDelete = (role === "teamMember") ? scope?.images?.images?.includes("delete") : (role === 'client') ? false : true;

    const handleSelectedAction = (val) => {
        setMyState(setState, { selectedAction: val });
        // closeAllMenu();
        if (val == 'delete') {
            setMyState(setState, { removeMe: state.selectedFolder })
        } else if (val == 'move') {
            setMyState(setState, { moveToPopUp: true })
        }

    }

    const handleSelectDesign = (val, design) => {

        if (val) {
            setMyState(setState, { selectedFolder: [...state.selectedFolder, design], checkLocalType: false })
            isTrash && displayButtons([...state.selectedFolder, design].length)
        } else {
            const arrCopy = Array.from(state.selectedFolder);


            const objWithIdIndex = arrCopy.findIndex((obj) => obj === design);
            arrCopy.splice(objWithIdIndex, 1);
            setMyState(setState, { selectedFolder: arrCopy, checkLocalType: false })
            isTrash && displayButtons(arrCopy.length);
        }
    }

    return (
        <>
            <Helmet >
                <title>{pageTitle}</title>
            </Helmet>

            {/* Main Wrapper  */}
            <main className={`sch-main-wrapper ${!isTrash ? 'sch-white-bg' : ''}`}>
                {/* banner */}
                {!isTrash && <div className="sch-banner-wrapper">
                    <div className="pxl-images-project-mainWrapper-box">
                        <div className="sch-container-fluid">
                            <div className="sch-banner-box pxl-dashboardBanner-box">
                                <div className="sch-banner-content">
                                    <h2>Discover Your Design Repository</h2>
                                    <p>Explore a Wealth of Design Projects and Initiate New Endeavors</p>

                                    <div className="sch-search-box">
                                        <div className="sch-search-row">
                                            <input type="text" value={state.keyword} placeholder="Search Project" onChange={(e) => setMyState(setState, {
                                                keyword: e.target.value,
                                                loadMoreActive: false
                                            })}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter' || e.keyCode === 13 || e.which === 13) {
                                                        getMyProject();
                                                    }
                                                }} />
                                            <button type="button" onClick={() => getMyProject()} className="sch-search-btn">
                                                <span> <img src="/assets/images/scheduler/search.svg" /> </span>
                                                {/* {!state.keyword ? <span> <img src="/assets/images/scheduler/search.svg"  /> </span> : <span className="cun-inputCross" onClick={clearSearch}>{svg.app.crossSvg}</span>} */}

                                            </button>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>}
                {/* Trending Templates Section */}

                <div className={`pxl-mydesign-createProjectBox ${!isTrash ? 'sch-templates-topspace' : ''} `}>

                    <div className="sch-container-fluid">

                        {!isTrash && <div className="sch-myDesignFlex pxl-myProject4Box">
                            <div className="pxl-uncategorize-heading">
                                <h2>Project</h2>
                            </div>
                            {state.totalRecord!=0 &&<div className="cun-headerSelect-wrap ">

                                <div className="cun-AllChecknoxSelect">
                                    <div className="sch-custom-check-box ">
                                        <div className="form-group">
                                            <input type="checkbox" id="select-all"
                                             disabled={( containsImagesDelete)? false : true}
                                                checked={state.localCheckAll}
                                                onChange={(e) => e.target.checked ? setMyState(setState, { localCheckAll: e.target.checked, selectedFolder: myProjectFolderList.filter(val => val._id !== null && val._id !== undefined).map(val => val._id), checkLocalType: true }) : setMyState(setState, { selectedFolder: [], localCheckAll: e.target.checked, checkLocalType: true })}
                                            />
                                            <label htmlFor="select-all">Select All</label>
                                        </div>
                                    </div>
                                </div>

                                <div className="cun-headreSelect">
                                    <Select options={options} 
                                    isSearchable={false}
                                    placeholder="Select Action"
                                        closeMenuOnSelect={true}
                                        isDisabled={state.selectedFolder.length == 0}
                                        value={options.filter(option =>
                                            option.value == state.selectedFolder)}
                                        onChange={(e) => handleSelectedAction(e.value)}
                                    />
                                </div>

                            </div>}
                        </div>}
                        {/* {!isTrash && <div className="sch-borderNoneTitleDouble sch-rightTitleNone sch-space-between">
                            <Title title="Projects" />
                            <button className={`sch-btn ${state.selectedFolder.length == 0 && 'pxl-button-disable'}`} disabled={state.selectedFolder.length == 0} onClick={() => setMyState(setState, { removeMe: state.selectedFolder })}><span>Delete</span></button>
                        </div>
                        } */}

                        <div className="sch-workspace-main-wrapper">

                            <div className="sch-workspace-row">
                                { !isTrash && myProjectFolderList.length != 0 && containsWrite && <>
                                    <div className="sch-workspace-box-fix">
                                        <a href="#" onClick={() => handleShow()}>
                                            <img src="/assets/images/scheduler/file-image.png" />
                                            <h2 className="sch-plus-icon-button"> Create New Project </h2>
                                        </a>
                                    </div>

                                </>}


                                {
                                    !state.loadMoreActive && state.isLoading ? [...Array(10)].map((count) => <div key={count}> {skeletonLoader('asset', 1, '100%', 320, count)}</div>)
                                        :
                                        myProjectFolderList.length > 0 && myProjectFolderList.map((item, i) => (
                                            <div className={`sch-workspace-repear-box ${state.selectedFolder.some((obj) => obj === item._id) && 'active_post'}`} key={i}>

                                                {item._id && <div className="sch-custom-check-box cun-select" style={{ opacity: state.selectedFolder.length > 0 && 1 }}>
                                                    {containsImagesDelete&&<div className="form-group">
                                                        <input type="checkbox" id={i}
                                                         disabled={( containsImagesDelete)? false : true}
                                                            checked={state.selectedFolder.some((obj) => obj === item._id)}
                                                            onChange={(e) => handleSelectDesign(e.target.checked, item._id)} />
                                                        <label htmlFor={i}></label>
                                                    </div>}
                                                </div>}
                                                <div className="sch-workspace-inenr-img-flex">
                                                    <Link to={!isTrash ? `/images/my-designs/projects/${item?._id || 'uncategorized'}` : '#'} className="full_link"></Link>
                                                    {(item.designs.data).map((image, i) => (

                                                        <div className="sch-workspace-inner-box" key={i}>
                                                            <a ><img src={image?.files?.original?.thumb ? assetsUrlPath(image?.files?.original?.thumb) : "/assets/images/default-draft-img.jpg"} /></a>
                                                        </div>

                                                    ))}
                                                    {item?.designs?.data?.length < 3 && (Array.from({ length: 3 - (item?.designs?.data?.length) })).map((image, i) => (

                                                        <div className="sch-workspace-inner-box" key={i}>
                                                            <a href="#" onClick={(e)=>{
                                                                e.preventDefault()
                                                                }}><img src={"/assets/images/default-draft-img.jpg"} /></a>
                                                        </div>

                                                    ))}

                                                    <div className="sch-workspace-inner-box sch-workspace-file-box">
                                                        <Link to={!isTrash ? `/images/my-designs/projects/${item?._id || 'uncategorized'}` : '#'}>
                                                            <img src="/assets/images/scheduler/file-image.png" />
                                                            <p className="sch-workspace-files">{item?.designs?.count ? item?.designs?.count : 0} files</p>
                                                        </Link>
                                                    </div>

                                                </div>

                                                <div className="sch-section-flex">
                                                    <div className="sch-workspace-info">
                                                        <h2><Link to={!isTrash ? `/images/my-designs/projects/${item?._id || 'uncategorized'}` : '#'}>{item.title}</Link></h2>
                                                        {
                                                            item?._id && <h3>
                                                                <img src="/assets/images/scheduler/calendar-svg.svg"></img><span>{dateFormatter(item.createdAt)}  </span>

                                                            </h3>
                                                        }
                                                    </div>
                                                    <div className='sch-draft-content'>
                                                        {
                                                            item?._id ? (containsWrite || containsImagesDelete) ?
                                                                isTrash ?
                                                                    <>
                                                                        <div className={`sch-menuSection  ${allsubmenu[item?._id] ? 'sch-menuOpen' : ''}`}>
                                                                            <div className='three_dot_bg' id={item?._id} onClick={() => toggleSublist(item?._id)} ref={actionRef}><img src="/assets/images/three-dot.svg" alt="" /></div>
                                                                            <div className={`sch-submenubox`}>
                                                                                <ul>
                                                                                    {containsWrite && <li>
                                                                                        <a onClick={() => { setallsubmenu(false); setMyState(setState, { restoreMe: [item._id] }) }} >
                                                                                            <span><img src="/assets/images/restore-svg.svg" alt="" /> </span>Restore
                                                                                        </a>
                                                                                    </li>}
                                                                                    {containsImagesDelete && <li>
                                                                                        <a onClick={() => { setallsubmenu(false); setMyState(setState, { removeMe: [item._id] }) }}>
                                                                                            <span><img src="/assets/images/delet-svg.svg" alt="" /> </span>Delete
                                                                                        </a>
                                                                                    </li>}
                                                                                </ul>
                                                                            </div>
                                                                        </div>

                                                                    </>

                                                                    :
                                                                    <>
                                                                        <div className={`sch-menuSection  ${allsubmenu[item?._id] ? 'sch-menuOpen' : ''}`}>
                                                                            <div className='three_dot_bg' onClick={() => toggleSublist(item?._id)} ref={actionRef} >
                                                                                <a id={item?._id}  ><img src="/assets/images/three-dot.svg" alt="" /></a>
                                                                            </div>
                                                                            <div className={`sch-submenubox`}>
                                                                                <ul>
                                                                                    {/* {containsWrite && <li>
                                                                                            <a href="#" onClick={(e)=>{
                                                                                                e.preventDefault()
                                                                                                }}>
                                                                                            <button onClick={() => { setMyState(setState, { previewPopUp: true, previewData: item }) }} >
                                                                                                <span><img src="/assets/images/viewSvg.svg" alt="" /> </span> Preview
                                                                                            </button>
                                                                                        </a>
                                                                                    </li>} */}
                                                                                    {containsWrite && <li>
                                                                                        <a onClick={() => {
                                                                                            setallsubmenu(false);
                                                                                            handleShow(item);
                                                                                            setMyState(setState, { targetId: item._id })
                                                                                        }}>
                                                                                            <span><img src="/assets/images/edit-svg.svg" alt="" /> </span>Edit
                                                                                        </a>
                                                                                    </li>}
                                                                                    {containsImagesDelete && <li>
                                                                                        <a onClick={() => { setallsubmenu(false); setMyState(setState, { removeMe: [item._id] }) }}>
                                                                                            <span><img src="/assets/images/delet-svg.svg" alt="" /> </span>Delete
                                                                                        </a>
                                                                                    </li>}
                                                                                </ul>
                                                                            </div>
                                                                        </div>

                                                                    </> : null
                                                                : <></>
                                                        }
                                                    </div>
                                                </div>
                                                {isTrash && <div className="sch-custom-check-box cun-select sch_hide_checkbox" style={{ opacity: state.selectedFolder.length > 0 && 1 }}>
                                                    <div className="form-group">

                                                        <input type="checkbox"
                                                            id={i}
                                                            checked={state.selectedFolder.some((obj) => obj === item._id)}
                                                            onChange={(e) => handleSelectProject(e.target.checked, item)}
                                                        />
                                                        <label htmlFor={i}></label>
                                                    </div>
                                                </div>}

                                            </div>
                                        ))

                                }

                                {/* {state.loadMoreActive && state.isLoading ? [...Array(10)].map((i) => <div key={i}>{skeletonLoader('asset', 1, 336, 320)}</div>) : ''} */}
                            </div>
                            {!state.isLoading && myProjectFolderList.length == 0 && <NoDataFound title={'No project found.'} type={!isTrash && 'design'} btntext={'Create Project'} onCreate={handleShow} showbtn={state.keyword} changeDesign={true} bgRemove={true} />}

                            {
                                myProjectFolderList.length < state.totalRecord &&
                                <div className="loadmorebtn">
                                    <a
                                        className={`sch-loadmoreBtn ${myProjectFolderList.length >= state.totalRecord ? 'pxl-button-disable' : ''}`}
                                        onClick={() => {
                                            getMyProject('loadMore')
                                            setMyState(setState, { selectedFolder: [], localCheckAll: false, checkLocalType: false })
                                        }}
                                    >{state.isLoading ? <span>Loading...</span> : <span>Load More</span>}</a>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </main>
{/* 
            <PreviewPost
                shownPopup={state.previewPopUp}
                closePopup={() => {
                    setMyState(setState, {
                        previewPopUp: false
                    })
                }}
                data={{ title: state.previewData.title, caption: state.previewData.caption, mediaUrl: state.previewData.mediaUrl, accounts: state.previewData.postOn, successData: state.previewData?.successData }}
            /> */}

            <Modal
                show={showModal} onHide={handleClose} animation={false}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="pxl_modalDv"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {state.targetId ? 'Edit Project' : 'Create New Project'}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className="" action="" onSubmit={handleCreate}>
                        <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Project name <span className="pxl-star-red">*</span></label>
                            <input type="text" className="form-control" value={state.title} placeholder=" Enter Project Name " onChange={(e) => setMyState(setState, { title: e.target.value })} />
                        </div>
                        <button disabled={state.isProcessing} type="submit" className="pxl_btn">{state.isProcessing ? 'Processing...' : state.targetId ? "Update" : 'Submit'}</button>
                    </form>
                </Modal.Body>

            </Modal>

            <ConfirmationPopup
                shownPopup={state.removeMe.length}
                closePopup={() => {
                    setMyState(setState, {
                        removeMe: [],
                        selectedFolder: [],
                        localCheckAll: false
                    })
                    isTrash && updateAction('');
                    isTrash && displayButtons(0);
                }}
                type={"project"}
                btnLoader={state.isDataload}
                title={isTrash ? `Are you sure you want to delete ${state.removeMe.length} ${state.removeMe.length == 1 ? 'project' : 'projects'} ?` : `Do you want to move ${state.removeMe.length} ${state.removeMe.length === 1 ? 'project' : 'projects'} to trash?`}
                subTitle={isTrash ? '' : 'Items can be restored from trash in next 15 days.'}
                yesBtn={isTrash ? "Yes, Delete" : "Yes, Move"}
                removeAction={() => {
                    let data = {}

                    // const idArray = state.removeMe;
                    const idArray = state.removeMe.map((item) => item);


                    // if (isTrash) {
                    //     data['target'] = idArray.join(',');
                    // } else {
                    data['target'] = idArray
                    // }

                    setMyState(setState, { isProcessing: true, isDataload: true });
                    hitApi({
                        url: isTrash ? "image/folder/remove" : 'image/folder/move-to-trash',
                        method: isTrash ? "DELETE" : "PATCH",
                        data: data,
                    }, (resp, err = null) => {
                        setMyState(setState, { isProcessing: false, isDataload: false });

                        if (resp.status) {
                            setMyState(setState, { isLoad: !state.isLoad });
                        }
                        setMyState(setState, {
                            removeMe: [],
                            selectedFolder: []
                        })
                        isTrash && updateAction('');
                        isTrash && displayButtons(0);
                    })
                }}
            />

            {/* Restore PopUp */}
            <ConfirmationPopup
                shownPopup={state.restoreMe.length}
                closePopup={() => {
                    setMyState(setState, {
                        restoreMe: [],
                        selectedFolder: []
                    })
                    isTrash && updateAction('');
                    isTrash && displayButtons(0);
                }}
                type={"project"}
                btnLoader={state.isDataload}

                title={`Are you sure you want to restore ${state.restoreMe.length} ${state.restoreMe.length == 1 ? 'project' : 'projects'} ?`}
                subTitle={'This item will be restore immediately.'}
                yesBtn={"Yes, Restore"}
                removeAction={() => {
                    let data = {}
                    // const idArray = state.restoreMe.map((item) => item._id);
                    const idArray = state.restoreMe;

                    data['target'] = idArray

                    // let data = { 'target': state.restoreMe }
                    setMyState(setState, { isProcessing: true, isDataload: true });
                    hitApi({
                        url: `image/folder/recover`,
                        method: "PATCH",
                        data: data,
                    }, (resp, err = null) => {
                        setMyState(setState, {
                            restoreMe: [],
                            selectedFolder: [],
                            isProcessing: false,
                            isDataload: false
                        })
                        isTrash && updateAction('');
                        isTrash && displayButtons(0);
                        if (resp.status) {
                            setMyState(setState, { isLoad: !state.isLoad });
                        }
                    })
                }}
            />
        </>
    )
};

export default Projects;