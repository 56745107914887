import  {  useState,useEffect,useRef } from 'react';
import { Modal } from 'react-bootstrap';
import { commonToast, hitApi } from '../../../components/commonAction';
import {useNavigate } from 'react-router-dom';


function CreateProjectPopUP({
    openPopUp,
    onClose,
    app
}) {
    const [projectName, setProjectName] = useState('');
    const [isbtn, setIsbtn] = useState(false);
    const navigate = useNavigate();
    const firstInputRef = useRef(null);

    useEffect(() => {
        if (firstInputRef.current) {
            firstInputRef.current.focus();
        }
    }, [openPopUp]);
    

    const handleSubmit = (e) => {
        e.preventDefault();
        
        if (!projectName.trim()) {
            commonToast('error', `Project Name is required!`);
            return false;
        } else {
            let data = {};
            if(app == 'builder'){
                data['title'] = projectName;
            }else{
                data['project_name'] = projectName;
            }
            setIsbtn(true)

            hitApi({
                url: app == 'builder' ? 'page-builder/add-project'  : 'pixa-support/api/create_project',
                method: "POST",
                data: data,
                loading: true,
                alert: true,
            }, (resp, err = null) => {
                setIsbtn(false)
                if (resp.status) {
                  
                    let respData = resp.data;
                    if (respData._id) {
                        onClose();
                        setProjectName('')
                        if(app == 'builder'){
                            window.location.href=`/builder/projects`;
                        }else{
                            navigate(`/pixa-support/create-project?id=${respData._id}&name=${projectName}`);
                        }
                    }
                }
            });
        }
    }

  return (
    
           <Modal
                show={openPopUp} onHide={()=>{
                    setIsbtn(false)
                    onClose();
                    setProjectName('');
                }} animation={true}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="pxl_modalDv pxl-createProject-modal"
            >


                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <div className='pxl-model-header'>
                            <h2 className='modal-title'>Create Project</h2>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <div className='modal-body'>
                    <form action="" className=''
                        onSubmit={handleSubmit}
                    >
                        <div className="pxl_input_feilds mb-3">
                            <label>Project Name <span className="pxl-star-red">*</span></label>
                            <input ref={firstInputRef} type="text" name="" placeholder="Enter Project Name" value={projectName} onChange={(e) => setProjectName(e.target.value)} />
                        </div>
                        <button type="submit" className="pxlCommon-btn" disabled={isbtn}>
                            {isbtn ? "Processing..." : 'Add'}
                        </button>
                    </form>
                </div>

            </Modal>
    
  )
}

export default CreateProjectPopUP