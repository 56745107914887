import Dashboard from "./index";
import Projects from "./projects";
import appConfig from "./../../config/appConfig";
import {  Routes, Route } from "react-router-dom";
import ProjectSetting from "./components/ProjectSetting";
import PixaSupportSettings from "./components/Settings/PixaSupportSettings";
import Customers from "./Customer";
import TicketList from "./projectTickets/TicketList";
import TicketCreate from "./projectTickets/TicketCreate";
import TicketsReply from "./projectTickets/TicketsReply";
import PrivateRoute from "../PrivateRoute";


const SchedulerRouter = () => {
   
return (
    <>
       
        <Routes>
            <Route path="/" element={<PrivateRoute allowedRoles={['user','admin',"client"]}><Dashboard pageTitle={`${appConfig.appName} | Dashboard`} /></PrivateRoute>} />
            <Route path="/projects" element={<PrivateRoute allowedRoles={['user','admin','teamMember',"client"]}><Projects pageTitle={`${appConfig.appName} | Projects`} /></PrivateRoute>} />
            <Route path="/projects/:id" element={<PrivateRoute allowedRoles={['user','admin','teamMember',"client"]}><TicketList pageTitle={`${appConfig.appName} | Ticktes`} /></PrivateRoute>} />
            <Route path="/projects/:id/tickets/:t_id" element={<PrivateRoute allowedRoles={['user','admin','teamMember',"client"]}><TicketsReply pageTitle={`${appConfig.appName} | Ticktes`} /></PrivateRoute>} />
            {/* <Route path="/pixa-support/projects" element={<Projects pageTitle={`${appConfig.appName} | Projects`} />} /> */}
            <Route path="/settings" element={<PrivateRoute allowedRoles={['user','admin',"client"]}><PixaSupportSettings pageTitle={`${appConfig.appName} | Settings`} /></PrivateRoute>} />
            <Route path="/customers" element={<PrivateRoute allowedRoles={['user','admin',"client"]}><Customers pageTitle={`${appConfig.appName} | Customers`} /></PrivateRoute>} />
            <Route path="/create-project/:id?" element={<PrivateRoute allowedRoles={['user','admin',"client"]}><ProjectSetting pageTitle={`${appConfig.appName} | Project`} /></PrivateRoute>} />
            <Route path="/edit-project/:id?" element={<PrivateRoute allowedRoles={['user','admin',"client"]}><ProjectSetting pageTitle={`${appConfig.appName} | Project`} /></PrivateRoute>} />
            <Route path="/create-ticket/:id" element={<PrivateRoute allowedRoles={['user','admin','teamMember',"client"]}><TicketCreate pageTitle={`${appConfig.appName} | Ticket Create`} /></PrivateRoute>} />


        </Routes>
    </>
)
};

export default SchedulerRouter;