import { Helmet } from "react-helmet";
import svg from "./components/svg";
import { useNavigate, Link } from 'react-router-dom';
import styles from "./Project.module.css"
import { useEffect, useState } from "react";
import { NoDataFound, hitApi } from "../../components/commonAction";
import { common } from "./helper/Common";
import { Typography, Tooltip } from "@mui/material";
import { useStore } from "../../zustand/store";
import Skeleton from '@mui/material/Skeleton';


const Projects = ({pageTitle}) => {
    const navigate = useNavigate();

    let userData = useStore(state => state)['userData'];
    let { role, scope } = userData;

    const [projectList, setProjectList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(12);
    const [searchTerm, setSearchTerm] = useState('');
    const [shownoData, setShowNoData] = useState(false);
    const [loadMore,setLoadMore] = useState(false);
    const [customerProject, setCustomerProject] = useState('');
    const [cPage, setCPage] = useState(1);




    const fetchProjects = async (page, listPerPage = perPage, search = searchTerm, type) => {
        if (userData.role !== 'customer') {
            let curPage = (type == 'loadMore') ? page + 1 : 1;
           
            if(type === 'loadMore'){
                setLoadMore(true)
            }else{
                setLoading(true);
                setLoadMore(false);
            } 
            hitApi({
                method: 'POST',
                url: 'pixa-support/api/getProjects',
                data: {
                    searchTerm: searchTerm,
                    userId: userData.userId,
                    listPerPage: perPage,
                    page: curPage,
                },
                loading: true,
                alert: false
            }, (resp) => {
                setLoading(false);
                setLoadMore(false);

                if (resp.status === 1) {
                    setCPage(curPage);
                   if (resp.data.length === 0) {
                        setShowNoData(true);
                    } else {
                        setShowNoData(false);
                    }
                    let newList = type == 'loadMore' ? [...projectList, ...resp.data] : resp.data;

                    setProjectList(newList);
                    // setPerPage(resp.perPage);
                    setTotalRows(resp.totalProject);

                } else {
                    setProjectList([])
                    setShowNoData(true);
                    setTotalRows('0');

                }
            });
        } else {
            setLoading(true)
            hitApi({
                method: 'POST',
                url: 'pixa-support/api/project_details',
                data: {
                    project_id: userData.project_id ? userData.project_id[0] : '',
                },
                loading: true,
                alert: false
            }, (resp) => {
                if (resp.status === 1) {
                    setLoading(false)
                    if (resp.data.length === 0) {
                        setShowNoData(true);
                    } else {
                        setShowNoData(false);
                    }
                    setCustomerProject(resp.data);

                }else{
                    setLoading(false);
                    setShowNoData(true);

                }
            });
        }
    };

    useEffect(() => {
        fetchProjects();
    }, []);

    const handleSearchKeyupEvent = async (e) => {
        let t = e.target;
        let searchTerm = t.value;
        setSearchTerm(searchTerm);
        if (e.keyCode === 13) {
            fetchProjects(0);
        }
    }

    useEffect(() => {
        document.body.classList.add('pxl-body-white');
        return () => {
          document.body.classList.remove('pxl-body-white');
        };
      }, []);

    const containsSupporProjecttWrite = (role === "teamMember") ? scope?.support ? scope?.support['add project']?.includes("write") : true : (role === 'client') ? false : true;

    // const [items, setItems] = useState();

    // useEffect(() => {
    //   localStorage.setItem('isOpen', JSON.stringify(items));
    // }, [items]);



    // outside click menu close end
    // if (userData.role === -1 || isError) {
    //     return <Errorpage />
    // } else {

    const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + '...';
        }
        return text;
    };
    return (
        <>
            <Helmet>
                <title>{pageTitle}</title>
            </Helmet>
            <div className="pxl-pixaSupport-mainWrapper">
                <div className="sch-container-fluid">
                    <div className="pxl-projectMainWrapper">
                        <div className='dash_head'>

                            <h3 className="xs_page_title">
                                {"Projects (" + totalRows + ")"}
                            </h3>


                            {/* {(projectList.length > 0) &&  */}
                            <div className="sch-search-section">
                                <div className={`sch-search-row sch-search-bg-color`}>
                                    <input placeholder="Search Here..."
                                        type='text'
                                        value={searchTerm}
                                        onChange={e => {
                                            setSearchTerm(e.target.value)
                                        }}
                                        onKeyUp={handleSearchKeyupEvent}
                                    />
                                    <button type="button" className="sch-search-btn" onClick={()=>{handleSearchKeyupEvent(searchTerm)}}>
                                        <img src="/assets/images/scheduler/search.svg" />
                                    </button>
                                </div>

                            </div>
                            {/* } */}


                        </div>

                        <div className={styles.xs_projects_wr}>

                            {(userData.role !== 'customer' && !loading ) ? (projectList.length > 0) ? projectList.map((project, index) => (
                                <div key={project._id} data-index={index} className={styles.xs_projects_box}>

                                    <div className={styles.xs_project_name}>

                                        {/* <Link href={'/project/' + project._id}>  <span>{svg.project_box_icon}</span></Link> */}
                                        <div className={`${styles.xs_support_projectName}`}>
                                            <Link to={'/pixa-support/projects/' + project._id }>
                                                <div className="pxl-pixasupport-project-head-tooltip cun-iconTooltip">
                                                <h3>
                                                    {(project.project_name?.length >=12) ?`${project.project_name.slice(0,12)} ...`:project.project_name}
                                                </h3>
                                                {project.project_name.length >=12 &&<div className='cun-show-tooltip' onClick={(e) => e.stopPropagation()}>
                                                    <p>{project.project_name}</p>
                                                </div>}
                                                </div>
                                                <h4>(Click here to Open Ticket)</h4>
                                            </Link>
                                               
                                                <p>Created - {common.dateFormatter(project.createdAt)}</p>
                                        </div>
                                        <div className={styles.xs_project_icons_sec}>
                                        {(role != "teamMember")&&<ul>
                                                {containsSupporProjecttWrite ? <li>
                                                    <div className={styles.xs_project_menu_btns}>
                                                        <Tooltip title="Edit Project" placement="top" arrow>
                                                            <a onClick={() => navigate(`/pixa-support/edit-project?id=${project._id}`)}>{svg.dt_edit_icon}</a>
                                                        </Tooltip>
                                                    </div>
                                                </li>
                                                    : null}
                                            </ul>}
                                        </div>

                                    </div>
                                    {/* <span className={styles.xs_project_line}></span> */}
                                    <div className={styles.xs_project_details_main}>
                                        {/* <div className={styles.xs_project_details}>
                                            <div className={styles.xs_project_details_left}>
                                                <p>User name</p>
                                            </div>
                                            <div className={styles.xs_project_details_right_link}>
                                                
                                                {project.domain_name ?
                                                    <Link target="_blank" className={styles.support_link} href={project.domain_type === 1 ? 'https://' + project.domain_name : 'https://' + project.domain_name}>{project.domain_type === 1 ? project.domain_name : 'https://' + project.domain_name}</Link>
                                                    : <i style={{ color: '#F44336' }}><p>Not Available</p></i>
                                                }
                                            </div>
                                        </div> */}
                                        <div className={styles.xs_project_details}>
                                            <div className={styles.xs_project_details_left}>
                                                <p>Agents</p>
                                            </div>

                                                {console.log("project.agent_id",project.agent_id.length)}
                                                <div className={`${styles.xs_project_details_right} `}>
                                                    <div className={`${styles.xs_project_avatar_list} cun-socialIcon`}>
                                                        <p>
                                                            {/* Limit text to a certain number of characters */}
                                                            {truncateText(project.agent_id.map(agent => agent.name).join(', '), 20)}
                                                        </p>
                                                        {project.agent_id.map(agent => agent.name).join(', ').length>=20&&<div className='cun-show-tooltip'>
                                                            <p>{project?.agent_id.map(agent => agent.name).join(', ')}</p>
                                                        </div>}
                                                    </div>
                                                </div>
                                        </div>
                                            {/* <Tooltip title={project.agent_id.map(agent => agent.name).join(', ')} placement="top" arrow> */}
                                            {/* </Tooltip> */}

                                        <div className={styles.xs_project_details}>
                                            <div className={styles.xs_project_details_left}>
                                                <p>Open Tickets</p>
                                            </div>
                                            <div className={styles.xs_project_details_right}>
                                            {/* <p>{project.open_tickets_count}</p> */}
                                               
                                            {(project.open_tickets_count =='0') ? <p>{project.open_tickets_count}</p>: 
                                            <p>
                                                <Link to={`/pixa-support/projects/${project._id}?status=${'open'}`}>
                                                        {project.open_tickets_count} 
                                                </Link>
                                            </p>
                                            }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )) : null : null
                            }

                            {
                                (userData.role === 'customer') ? <div className={styles.xs_projects_box}>
                                    <div className={"mb-30 " + styles.xs_project_name}>
                                        <Link href={'/project/' + customerProject._id}>  <span>{svg.project_icon}</span></Link>
                                        <div>
                                            <h3>
                                                <Link href={'/project/' + customerProject._id}>
                                                    {customerProject.project_name
                                                        // + ' (Click here to Open Ticket)'
                                                    }
                                                </Link>
                                            </h3>
                                            <p>Created - {common.dateFormatter(customerProject.createdAt)}</p>
                                        </div>
                                    </div>

                                </div> : null
                            }

                            {
                               (loading || loadMore) ? [1, 2, 3, 4].map(x => <div key={x}>
                                    <div className={styles.skeleton_uperbox} >
                                        <Skeleton animation="wave" variant="circular" sx={{ width: { xl: 90, md: 60, xs: 40 }, height: { xl: 90, md: 60, xs: 40 } }} />
                                        <div className={styles.skeleton_text}>
                                            <Skeleton variant="text" sx={{ width: { xl: 230, md: 100, xs: 90 } }} height={10} />
                                            <Skeleton variant="text" sx={{ width: { xl: 230, md: 100, xs: 90 } }} height={10} />
                                        </div>
                                    </div>
                                    <Skeleton animation="wave" variant="rectangular" height={143} />
                                </div>) : null
                            }
                        </div>

                        {!loading && shownoData ?
                            <>
                                <NoDataFound title={'No Project Found.'} changeDesign={true} bgRemove={true} />

                            </>
                            : null
                        }
                    </div>
                </div>
            </div>
            {
                projectList?.length < totalRows &&
                <div className="loadmorebtn">
                    <a
                        className={`sch-loadmoreBtn ${projectList?.length >= totalRows ? 'pxl-button-disable' : ''}`}
                        onClick={() => {
                            if(loading || loadMore){
                                    
                            }else{
                                fetchProjects(cPage, perPage, "", 'loadMore');
                            }
                        }}
                    >{(loading || loadMore) ? <span>Loading...</span> : <span>Load More</span>}</a>
                </div>
            }


        </>
    )


    // }

}

export default Projects;